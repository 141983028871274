import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { GetWayfinderContentGQL } from "../../generated/graphql";
import { content, preloader } from "../shared/state/state.resolver";

@Injectable({
  providedIn: "root"
})
export class ContentResolver implements Resolve<void> {
  constructor(private getContent: GetWayfinderContentGQL) {}

  resolve(route: ActivatedRouteSnapshot): Observable<void> {
    return this.getContent.watch().valueChanges.pipe(
      map(result => {
        const contentBlocks = result.data.getWayfinderContentBlocks || [];
        content(contentBlocks);
        preloader(false);
      }),
      take(1)
    );
  }
}
