<div class="container">
  <div class="row">
    <div class="col-md-6 col-md-offset-3">
      <div class="soft-bottom">
        <app-fire-content-block id="{{getContentId()}}"></app-fire-content-block>
      </div>

      <div class="soft-bottom">
        <button (click)="doClick()" class="btn btn-blue btn-block btn-large" data-automation-id="wf-final-submit">Next</button>
      </div>
    </div>
  </div>
</div>