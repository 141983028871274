import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Log } from "../models/log";
import {
  AnswerFragment,
  AssessmentEmailStatusFragment,
  AssessmentFragment,
  ConfigFragment,
  ContentFragment,
  CreateWayfinderAssessmentGQL,
  GetAnswersGQL,
  GetAssessmentGQL,
  GetConfigGQL,
  GetContentGQL,
  GetCurrentUserGQL,
  GetQuestionsGQL,
  QuestionFragment,
  SendWayfinderAssessmentEmailGQL,
  UpdateWayfinderAssessmentGQL,
  UserFragment
} from "../../generated/graphql";
import { cloneDeep } from "@apollo/client/utilities";

@Injectable()
export class DataService {
  constructor(
    private getAnswers: GetAnswersGQL,
    private getConfig: GetConfigGQL,
    private getContent: GetContentGQL,
    private getQuestions: GetQuestionsGQL,
    private getCurrentUser: GetCurrentUserGQL,
    private getAssessment: GetAssessmentGQL,
    private createAssessment: CreateWayfinderAssessmentGQL,
    private updateAssessment: UpdateWayfinderAssessmentGQL,
    private sendAssessmentEmail: SendWayfinderAssessmentEmailGQL
  ) {}

  public log(log: Log) {
    // this.documentDB.firestore
    //   .collection("Logger")
    //   .doc(this.documentDB.createId())
    //   .set(this.prepareDocForAFS(log));
  }

  public GetAssessment(): Observable<AssessmentFragment> {
    return this.getAssessment.fetch().pipe(
      map(result => {
        const assessment = result.data.assessment;
        return assessment;
      })
    );
  }

  public GetAnswers(): Observable<AnswerFragment[]> {
    return this.getAnswers.watch().valueChanges.pipe(
      map(result => {
        const answers = result.data.answers || [];
        return answers;
      })
    );
  }

  public GetQuestions(): Observable<QuestionFragment[]> {
    return this.getQuestions.watch().valueChanges.pipe(
      map(result => {
        const questions = result.data.questions;
        return questions;
      })
    );
  }

  public GetConfig(): Observable<ConfigFragment> {
    return this.getConfig.watch().valueChanges.pipe(
      map(result => {
        return result.data.config;
      })
    );
  }

  public GetContentBlocks(): Observable<ContentFragment[]> {
    return this.getContent.watch().valueChanges.pipe(
      map(result => {
        return result.data.content || [];
      })
    );
  }

  public GetCurrentUser(): Observable<UserFragment> {
    return this.getCurrentUser.watch().valueChanges.pipe(
      map(result => {
        return result.data.currentUser;
      })
    );
  }

  public CreateAssessment(
    config: ConfigFragment,
    user: UserFragment
  ): Observable<AssessmentFragment> {
    return this.createAssessment
      .mutate({
        userId: user._id,
        version: config.currentversion
      })
      .pipe(
        map(result => {
          const assessment = result.data.createWayfinderAssessment;
          return assessment;
        })
      );
  }

  public UpdateAssessment(
    assessment: AssessmentFragment
  ): Observable<AssessmentFragment> {
    return this.updateAssessment
      .mutate({
        assessmentInput: this.ScrubAssessment(assessment)
      })
      .pipe(
        map(result => {
          const assessment = result.data.updateWayfinderAssessment;
          return assessment;
        })
      );
  }

  private ScrubAssessment(
    newAssessment: AssessmentFragment
  ): AssessmentFragment {
    const assessment = cloneDeep(newAssessment);

    delete assessment.__typename;

    if (assessment.groupMembership) {
      const groupMembership = cloneDeep(assessment.groupMembership);
      delete groupMembership.__typename;
      assessment.groupMembership = groupMembership;
    }

    assessment.responses = assessment.responses.map(response => {
      response = cloneDeep(response);
      delete response.__typename;

      const question = cloneDeep(response?.question);
      delete question?.__typename;
      response.question = question;

      return response;
    });

    return assessment;
  }

  public SendAssessmentEmail(
    id: string,
    resend: boolean
  ): Observable<AssessmentEmailStatusFragment> {
    return this.sendAssessmentEmail
      .mutate({
        id: id,
        resend: resend
      })
      .pipe(
        map(result => {
          const assesmentStatus = result.data.sendAssessmentEmail;
          return assesmentStatus;
        })
      );
  }
}
