import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type Achieved = {
  __typename?: 'Achieved';
  additionalNaming?: Maybe<Scalars['String']>;
  date: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
};

export type AdditionalServicesInput = {
  additionalRequests: Scalars['Boolean'];
  additionalRequestsDetails?: InputMaybe<Scalars['String']>;
  childcare: Scalars['Boolean'];
  childcareRequested?: InputMaybe<Scalars['Boolean']>;
  payment: Scalars['Boolean'];
  paymentDetails?: InputMaybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  id: Scalars['ID'];
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  zipcode: Scalars['String'];
};

export type AddressInput = {
  city: Scalars['String'];
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  zipcode: Scalars['String'];
};

export type AgeGroups = {
  __typename?: 'AgeGroups';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Album = Media & {
  __typename?: 'Album';
  authors?: Maybe<Array<Author>>;
  category?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  publisher?: Maybe<Scalars['String']>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  songs?: Maybe<Array<Song>>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type Allergy = {
  __typename?: 'Allergy';
  allergyDescription?: Maybe<Scalars['String']>;
  allergyId?: Maybe<Scalars['Int']>;
  allergyType?: Maybe<Scalars['String']>;
  allergyTypeId?: Maybe<Scalars['Int']>;
  medicalInformationAllergyId?: Maybe<Scalars['Int']>;
};

export type Article = Media & {
  __typename?: 'Article';
  authors?: Maybe<Array<Author>>;
  body?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  leadText?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type Author = Media & {
  __typename?: 'Author';
  authors?: Maybe<Array<Author>>;
  category?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type BackgroundCheckRule = {
  __typename?: 'BackgroundCheckRule';
  id: Scalars['ID'];
  isDriver?: Maybe<Scalars['Boolean']>;
  isFinance?: Maybe<Scalars['Boolean']>;
  ministryAliasName?: Maybe<Scalars['String']>;
  ministryId: Scalars['ID'];
  site?: Maybe<Site>;
};

export type Baptism = {
  __typename?: 'Baptism';
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year: Scalars['Int'];
};

export type BaptismInput = {
  day?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year: Scalars['Int'];
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Camp = {
  __typename?: 'Camp';
  endDate?: Maybe<Scalars['Float']>;
  eventId: Scalars['ID'];
  eventTitle?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['Int']>;
  primaryContactEmail?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['Int']>;
  registrationEndDate?: Maybe<Scalars['Float']>;
  registrationStartDate?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Float']>;
};

export type CampReservation = {
  __typename?: 'CampReservation';
  birthDate?: Maybe<Scalars['Float']>;
  contactId?: Maybe<Scalars['Int']>;
  currentGrade?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Float']>;
  eventId: Scalars['ID'];
  eventTitle?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  primaryContactEmail?: Maybe<Scalars['String']>;
  roommate?: Maybe<Scalars['String']>;
  schoolAttending?: Maybe<Scalars['String']>;
  schoolAttendingNext?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Float']>;
};

export type Category = Content & {
  __typename?: 'Category';
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type Communication = {
  __typename?: 'Communication';
  Attachment?: Maybe<CommunicationAttatchment>;
  AuthorUserId?: Maybe<Scalars['Int']>;
  Body?: Maybe<Scalars['String']>;
  Contacts: Array<Scalars['Int']>;
  FromContactId?: Maybe<Scalars['Int']>;
  FromEmail?: Maybe<Scalars['String']>;
  ParseData?: Maybe<Array<Maybe<ParseData>>>;
  RecipientEmails?: Maybe<Array<Scalars['String']>>;
  ReplyToContactId?: Maybe<Scalars['Int']>;
  SendAt?: Maybe<Scalars['Int']>;
  Subject?: Maybe<Scalars['String']>;
  TemplateId?: Maybe<Scalars['Int']>;
};

export type CommunicationAttatchment = {
  __typename?: 'CommunicationAttatchment';
  Content?: Maybe<Scalars['String']>;
  Filename?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type CommunicationAttatchmentInput = {
  Content?: InputMaybe<Scalars['String']>;
  Filename?: InputMaybe<Scalars['String']>;
  Type?: InputMaybe<Scalars['String']>;
};

export type CommunicationInput = {
  Attachment?: InputMaybe<CommunicationAttatchmentInput>;
  AuthorUserId?: InputMaybe<Scalars['Int']>;
  Body?: InputMaybe<Scalars['String']>;
  Contacts?: InputMaybe<Array<Scalars['Int']>>;
  FromContactId?: InputMaybe<Scalars['Int']>;
  FromEmail?: InputMaybe<Scalars['String']>;
  ParseData?: InputMaybe<Array<InputMaybe<ParseDataInput>>>;
  RecipientEmails?: InputMaybe<Array<Scalars['String']>>;
  ReplyToContactId?: InputMaybe<Scalars['Int']>;
  SendAt?: InputMaybe<Scalars['Int']>;
  Subject?: InputMaybe<Scalars['String']>;
  TemplateId?: InputMaybe<Scalars['Int']>;
};

export type ContactLeaderInput = {
  groupId: Scalars['ID'];
  message: Scalars['String'];
  subject: Scalars['String'];
};

export type Content = {
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  searchExcluded: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type ContentBlock = Content & {
  __typename?: 'ContentBlock';
  category: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  legacy_id?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  searchExcluded: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ContentBlockInput = {
  category?: InputMaybe<Array<Scalars['String']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateGroupInput = {
  additionalServices?: InputMaybe<AdditionalServicesInput>;
  ageGroups?: InputMaybe<Array<GroupAttributeInput>>;
  availableOnline: Scalars['Boolean'];
  capacity?: InputMaybe<Scalars['Int']>;
  childcareAvailable?: InputMaybe<Scalars['Boolean']>;
  customRegistrationLink?: InputMaybe<Scalars['String']>;
  demographics?: InputMaybe<Array<GroupAttributeInput>>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  focuses?: InputMaybe<Array<GroupAttributeInput>>;
  isLeader?: InputMaybe<Scalars['Boolean']>;
  isPrimaryContact?: InputMaybe<Scalars['Boolean']>;
  journeyActive?: InputMaybe<Scalars['Boolean']>;
  kidsWelcome?: InputMaybe<Scalars['Boolean']>;
  leaders?: InputMaybe<Array<Scalars['String']>>;
  meetingSchedule?: InputMaybe<MeetingScheduleInput>;
  meetsOnline?: InputMaybe<Scalars['Boolean']>;
  ministryId: Scalars['ID'];
  name: Scalars['String'];
  offsiteMeetingAddress?: InputMaybe<GroupAddressInput>;
  ongoing?: InputMaybe<Scalars['Boolean']>;
  parentGroup?: InputMaybe<ParentGroup>;
  primaryContactId: Scalars['ID'];
  registrationNotRequired?: InputMaybe<Scalars['Boolean']>;
  siteId: Scalars['ID'];
  staffContact?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Float'];
  typeId: Scalars['ID'];
};

export type Demographic = {
  __typename?: 'Demographic';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Donation = {
  __typename?: 'Donation';
  amount?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
};

export type DonationArgs = {
  endDate?: InputMaybe<Scalars['Int']>;
  includeCogiver?: InputMaybe<Scalars['Boolean']>;
  includeSoftDonations?: InputMaybe<Scalars['Boolean']>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startDate?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type EmailInput = {
  newEmail: Scalars['String'];
  oldEmail?: InputMaybe<Scalars['String']>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  primaryContact?: Maybe<Scalars['Boolean']>;
  relationship?: Maybe<Scalars['String']>;
};

export type Engagement = {
  __typename?: 'Engagement';
  achieved?: Maybe<Array<Achieved>>;
  achievement?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  excludedAudience?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  linkText?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  targetUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tooltipEarnedContent?: Maybe<Scalars['String']>;
  tooltipUnearnedContent?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type EngagementInput = {
  additionalNaming?: InputMaybe<Scalars['String']>;
  date: Scalars['Float'];
  slug: Scalars['String'];
};

export type Episode = Media & {
  __typename?: 'Episode';
  authors?: Maybe<Array<Author>>;
  category?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  podcast?: Maybe<Podcast>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type FamilyMember = {
  __typename?: 'FamilyMember';
  endDate?: Maybe<Scalars['Float']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isCancelled?: Maybe<Scalars['Boolean']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isPending?: Maybe<Scalars['Boolean']>;
  isSignedUp?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  signedUpDate?: Maybe<Scalars['Float']>;
};

export type FeelingResponse = {
  __typename?: 'FeelingResponse';
  id: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
};

export type FeelingResponseInput = {
  id: Scalars['ID'];
  value?: InputMaybe<Scalars['String']>;
};

export type Focus = {
  __typename?: 'Focus';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Fraction = {
  __typename?: 'Fraction';
  denominator?: Maybe<Scalars['Int']>;
  numerator?: Maybe<Scalars['Int']>;
};

export type Geolocation = {
  __typename?: 'Geolocation';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Group = {
  __typename?: 'Group';
  ageGroups?: Maybe<Array<AgeGroups>>;
  availableOnline?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Int']>;
  category?: Maybe<GroupCategory>;
  childcareAvailable?: Maybe<Scalars['Boolean']>;
  crossroadsHosted?: Maybe<Scalars['Boolean']>;
  currentParticipant?: Maybe<GroupParticipant>;
  customRegistrationLink?: Maybe<Scalars['String']>;
  demographics?: Maybe<Array<Demographic>>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  focuses?: Maybe<Array<Focus>>;
  geolocation?: Maybe<Geolocation>;
  groupDetails?: Maybe<GroupDetails>;
  /** @deprecated No longer supported */
  groupParticipantId?: Maybe<Scalars['ID']>;
  groupType: Scalars['String'];
  hasWaitList?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['String']>;
  isGroupFull?: Maybe<Scalars['Boolean']>;
  isMeeting?: Maybe<Scalars['Boolean']>;
  journeyActive?: Maybe<Scalars['Boolean']>;
  kidsWelcome?: Maybe<Scalars['Boolean']>;
  /** @deprecated use leaders instead */
  leader?: Maybe<GroupParticipant>;
  leaders?: Maybe<Array<GroupParticipant>>;
  meetingSchedule?: Maybe<MeetingSchedule>;
  meetsOnline?: Maybe<Scalars['Boolean']>;
  ministry?: Maybe<GroupMinistry>;
  name?: Maybe<Scalars['String']>;
  obscuredGeolocation?: Maybe<ObscuredGeolocation>;
  offsiteMeetingAddress?: Maybe<GroupAddress>;
  ongoing?: Maybe<Scalars['Boolean']>;
  parentGroup?: Maybe<Group>;
  parentGroupTitle?: Maybe<Scalars['String']>;
  participantCount?: Maybe<Scalars['Int']>;
  participants?: Maybe<Array<GroupParticipant>>;
  pendingInvitations?: Maybe<Array<GroupInvitee>>;
  pendingJoinRequests?: Maybe<Array<JoinRequest>>;
  primaryContact?: Maybe<GroupParticipant>;
  registrationNotRequired?: Maybe<Scalars['Boolean']>;
  /** @deprecated No longer supported */
  role?: Maybe<GroupRole>;
  site?: Maybe<Site>;
  startDate?: Maybe<Scalars['Int']>;
  startsAt?: Maybe<Scalars['String']>;
  subGroups?: Maybe<Array<Group>>;
  targetAudience?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  url?: Maybe<Scalars['String']>;
  zoomLink?: Maybe<Scalars['String']>;
};


export type GroupSubGroupsArgs = {
  includePrivateGroups?: InputMaybe<Scalars['Boolean']>;
};

export type GroupAddress = {
  __typename?: 'GroupAddress';
  city: Scalars['String'];
  id: Scalars['ID'];
  state: Scalars['String'];
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type GroupAddressInput = {
  city: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type GroupAttributeInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type GroupCategory = {
  __typename?: 'GroupCategory';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum GroupCreationStatus {
  ChildcareReminder = 'CHILDCARE_REMINDER',
  PendingAdditionalServicesSetup = 'PENDING_ADDITIONAL_SERVICES_SETUP',
  PendingBackgroundCheck = 'PENDING_BACKGROUND_CHECK',
  PendingParentApproval = 'PENDING_PARENT_APPROVAL',
  Success = 'SUCCESS'
}

export type GroupDetails = {
  __typename?: 'GroupDetails';
  ageRange?: Maybe<Scalars['String']>;
  childcareAvailable?: Maybe<Scalars['Boolean']>;
  childcareUnavailable?: Maybe<Scalars['Boolean']>;
  demographic?: Maybe<Scalars['String']>;
  focus?: Maybe<Array<Scalars['String']>>;
  frequency?: Maybe<Scalars['String']>;
  inPerson?: Maybe<Scalars['Boolean']>;
  kidsUnwelcome?: Maybe<Scalars['Boolean']>;
  kidsWelcome?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  virtual?: Maybe<Scalars['Boolean']>;
};

export type GroupInvitation = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  groupId: Scalars['ID'];
  groupName: Scalars['String'];
  lastName: Scalars['String'];
  leaderNickname: Scalars['String'];
};

export type GroupInvitationDetails = {
  groupId: Scalars['ID'];
  groupName: Scalars['String'];
  invitationGUID: Scalars['ID'];
  inviteeId?: InputMaybe<Scalars['ID']>;
  leaderId: Scalars['ID'];
  leaderNickname: Scalars['String'];
  participantEmail?: InputMaybe<Scalars['String']>;
  participantFirstName?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['ID']>;
  participantLastName?: InputMaybe<Scalars['String']>;
};

export type GroupInvitationInfo = {
  __typename?: 'GroupInvitationInfo';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
  groupName?: Maybe<Scalars['String']>;
  invitationGUID?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  leaderId?: Maybe<Scalars['Int']>;
  leaderNickname?: Maybe<Scalars['String']>;
};

export type GroupInvitee = {
  __typename?: 'GroupInvitee';
  email: Scalars['String'];
  firstName: Scalars['String'];
  invitationGUID?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
};

export type GroupJoinRequestConfirmation = {
  __typename?: 'GroupJoinRequestConfirmation';
  groupId?: Maybe<Scalars['ID']>;
  groupName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  invitationGuid?: Maybe<Scalars['String']>;
  leaderName?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  toEmail?: Maybe<Scalars['String']>;
};

export type GroupJoinRequestInfo = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type GroupMinistry = {
  __typename?: 'GroupMinistry';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type GroupMutationResponse = {
  __typename?: 'GroupMutationResponse';
  group: Group;
  status?: Maybe<Array<GroupCreationStatus>>;
};

export type GroupParticipant = IUser & {
  __typename?: 'GroupParticipant';
  contactId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Float']>;
  firstName?: Maybe<Scalars['String']>;
  groupId: Scalars['ID'];
  groupParticipantId: Scalars['ID'];
  groupRole: GroupRole;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isPrimaryContact?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  leaderApprovalStatus?: Maybe<Scalars['Int']>;
  nickName?: Maybe<Scalars['String']>;
  participantId: Scalars['ID'];
  startDate: Scalars['Float'];
  user?: Maybe<User>;
};

export type GroupRole = {
  __typename?: 'GroupRole';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type GroupSiteInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type GroupType = {
  __typename?: 'GroupType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type GroupTypeInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export enum GroupTypeName {
  AgeOrGrade = 'AgeOrGrade',
  Journey = 'Journey',
  Onsite = 'Onsite',
  Parent = 'Parent',
  Serving = 'Serving',
  Small = 'Small',
  Waitlist = 'Waitlist'
}

export type HouseholdMember = IUser & {
  __typename?: 'HouseholdMember';
  dateOfBirth?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['ID']>;
  householdId?: Maybe<Scalars['ID']>;
  householdPosition?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  participantId: Scalars['ID'];
  phoneNumbers?: Maybe<Array<PhoneNumber>>;
  site?: Maybe<Site>;
};

export type IUser = {
  id: Scalars['ID'];
  participantId: Scalars['ID'];
};

export type InputTypeFormResponse = {
  id: Scalars['ID'];
  ref: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type Invitation = {
  __typename?: 'Invitation';
  groupId?: Maybe<Scalars['ID']>;
  groupName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  invitationGUID?: Maybe<Scalars['ID']>;
  leaderEmail?: Maybe<Scalars['String']>;
  leaderId?: Maybe<Scalars['ID']>;
  leaderLastname?: Maybe<Scalars['String']>;
  leaderNickname?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type JoinGroupRequest = {
  __typename?: 'JoinGroupRequest';
  groupId?: Maybe<Scalars['ID']>;
  submittedDate?: Maybe<Scalars['String']>;
};

export type JoinRequest = {
  __typename?: 'JoinRequest';
  id?: Maybe<Scalars['ID']>;
  participant?: Maybe<GroupParticipant>;
  requestDate?: Maybe<Scalars['String']>;
};

export type JoinRequestDetails = {
  communicationId?: InputMaybe<Scalars['ID']>;
  groupId?: InputMaybe<Scalars['ID']>;
  groupName?: InputMaybe<Scalars['String']>;
  leaderFirstName?: InputMaybe<Scalars['String']>;
  leaderId?: InputMaybe<Scalars['ID']>;
  participantEmail?: InputMaybe<Scalars['String']>;
  participantFirstName?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['ID']>;
  participantLastName?: InputMaybe<Scalars['String']>;
  requestorId?: InputMaybe<Scalars['ID']>;
};

export type LeaveGroupInput = {
  email: Scalars['String'];
  groupId: Scalars['ID'];
  groupName: Scalars['String'];
  groupParticipantId: Scalars['ID'];
  lastName: Scalars['String'];
  nickName: Scalars['String'];
};

export type LifeStage = Content & {
  __typename?: 'LifeStage';
  content?: Maybe<Array<Maybe<LifeStageContent>>>;
  contentTotal: Scalars['String'];
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  searchExcluded: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type LifeStageContent = Media & {
  __typename?: 'LifeStageContent';
  authors?: Maybe<Array<Author>>;
  category?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  lifeStageid: Scalars['ID'];
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type LifeStageInput = {
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type MaritalStatus = {
  __typename?: 'MaritalStatus';
  id: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
};

export type MaritalStatusInput = {
  id: Scalars['Int'];
  value?: InputMaybe<Scalars['String']>;
};

export type Media = {
  authors?: Maybe<Array<Author>>;
  category?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type MediaMetric = {
  __typename?: 'MediaMetric';
  content: Media;
  date: Scalars['Float'];
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<MediaMetricType>;
};

export type MediaMetricInput = {
  slug: Scalars['String'];
  type: MediaMetricType;
};

export enum MediaMetricType {
  Article = 'ARTICLE',
  Episode = 'EPISODE',
  Message = 'MESSAGE',
  Perspective = 'PERSPECTIVE',
  Podcast = 'PODCAST',
  Series = 'SERIES',
  Video = 'VIDEO'
}

export type MediaMetrics = {
  __typename?: 'MediaMetrics';
  likes?: Maybe<Array<Maybe<MediaMetric>>>;
  shares?: Maybe<Array<Maybe<MediaMetric>>>;
  views?: Maybe<Array<Maybe<MediaMetric>>>;
};

export type MedicalInformation = {
  __typename?: 'MedicalInformation';
  allergies?: Maybe<Array<Allergy>>;
  contactId?: Maybe<Scalars['Int']>;
  insuranceCompany?: Maybe<Scalars['String']>;
  medicalInformationId?: Maybe<Scalars['Int']>;
  medications?: Maybe<Array<Medication>>;
  medicationsAdministered?: Maybe<Array<Maybe<Scalars['String']>>>;
  physicianName?: Maybe<Scalars['String']>;
  physicianPhone?: Maybe<Scalars['String']>;
  policyHolder?: Maybe<Scalars['String']>;
  showAllergies?: Maybe<Scalars['Boolean']>;
  showMedications?: Maybe<Scalars['Boolean']>;
};

export type Medication = {
  __typename?: 'Medication';
  dosage?: Maybe<Scalars['String']>;
  medicalInformationMedicationId?: Maybe<Scalars['Int']>;
  medicationName?: Maybe<Scalars['String']>;
  medicationType?: Maybe<Scalars['Int']>;
  remove?: Maybe<Scalars['Boolean']>;
  timeOfDay?: Maybe<Scalars['String']>;
};

export type MeetingDay = {
  __typename?: 'MeetingDay';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type MeetingDayInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MeetingFrequency = {
  __typename?: 'MeetingFrequency';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MeetingFrequencyInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MeetingSchedule = {
  __typename?: 'MeetingSchedule';
  day?: Maybe<MeetingDay>;
  endTime?: Maybe<Scalars['String']>;
  frequency?: Maybe<MeetingFrequency>;
  time?: Maybe<Scalars['String']>;
};

export type MeetingScheduleInput = {
  dayId?: InputMaybe<Scalars['ID']>;
  endTime?: InputMaybe<Scalars['String']>;
  frequencyId?: InputMaybe<Scalars['ID']>;
  time?: InputMaybe<Scalars['String']>;
};

export type Message = Media & {
  __typename?: 'Message';
  authors?: Maybe<Array<Author>>;
  category?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  series?: Maybe<Series>;
  slug?: Maybe<Scalars['String']>;
  subTitles?: Maybe<Scalars['String']>;
  subTitlesFileUrl?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type Meta = {
  __typename?: 'Meta';
  description?: Maybe<Scalars['String']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  imageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  SendCommunication: Communication;
  _?: Maybe<Scalars['Boolean']>;
  acceptInvitation?: Maybe<Invitation>;
  acceptJoinRequest?: Maybe<GroupParticipant>;
  acceptSpouseInvite?: Maybe<SpouseInvite>;
  addLike?: Maybe<Scalars['Boolean']>;
  addParticipantToGroup: GroupParticipant;
  addShare?: Maybe<Scalars['Boolean']>;
  addView?: Maybe<Scalars['Boolean']>;
  cancelInvitation?: Maybe<Invitation>;
  cancelSpouseInvite?: Maybe<SpouseInvite>;
  changeParticipantGroupRole?: Maybe<GroupParticipant>;
  contactLeader?: Maybe<Scalars['Boolean']>;
  createGroup: GroupMutationResponse;
  createUser?: Maybe<User>;
  createWayfinderAssessment: WayfinderAssessment;
  createWayfinderUser: WayfinderUser;
  declineInvitation?: Maybe<Invitation>;
  declineSpouseInvite?: Maybe<SpouseInvite>;
  denyJoinRequest?: Maybe<Scalars['Boolean']>;
  generateCampActivities?: Maybe<Scalars['Boolean']>;
  generateGoTripBadgesAndActivities?: Maybe<Scalars['Boolean']>;
  generateGroupBadgesAndActivities?: Maybe<Scalars['Boolean']>;
  generateGroupLeaderBadge?: Maybe<Scalars['Boolean']>;
  inviteMySpouse?: Maybe<SpouseInvite>;
  inviteNewGroupParticipant?: Maybe<GroupInvitationInfo>;
  leaveGroup?: Maybe<GroupParticipant>;
  logFeelingResponse?: Maybe<Scalars['Boolean']>;
  logTypeFormResponse?: Maybe<Scalars['Boolean']>;
  practice?: Maybe<Practice>;
  practiceGoal?: Maybe<PracticeGoal>;
  registerParticipantsForGroup?: Maybe<Array<Maybe<GroupParticipant>>>;
  removeParticipantFromGroup?: Maybe<GroupParticipant>;
  requestToJoinGroup?: Maybe<GroupJoinRequestConfirmation>;
  resendResultsEmail: Scalars['Boolean'];
  resetEngagements?: Maybe<Scalars['Boolean']>;
  sendAssessmentEmail: WayfinderAssessmentEmailStatus;
  setBaptism?: Maybe<User>;
  setClosestSite?: Maybe<User>;
  /** @deprecated use removeParticipantFromGroup instead */
  setGroupParticipantEndDate?: Maybe<GroupParticipant>;
  setGroupPrivacy?: Maybe<Group>;
  setGroupsEndDate?: Maybe<Array<Group>>;
  /** set the lifeStage of user */
  setLifeStage?: Maybe<User>;
  setNewUserData?: Maybe<Scalars['Boolean']>;
  /** set the site of user */
  setSite?: Maybe<User>;
  updateGroup: GroupMutationResponse;
  updateUserProfile?: Maybe<User>;
  updateWayfinderAssessment: WayfinderAssessment;
};


export type MutationSendCommunicationArgs = {
  communicationInput?: InputMaybe<CommunicationInput>;
};


export type MutationAcceptInvitationArgs = {
  invitationDetails?: InputMaybe<GroupInvitationDetails>;
};


export type MutationAcceptJoinRequestArgs = {
  joinRequestDetails?: InputMaybe<JoinRequestDetails>;
};


export type MutationAcceptSpouseInviteArgs = {
  inviteId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAddLikeArgs = {
  mediaMetric: MediaMetricInput;
};


export type MutationAddParticipantToGroupArgs = {
  groupId: Scalars['ID'];
  participantId: Scalars['ID'];
  roleId: Scalars['ID'];
};


export type MutationAddShareArgs = {
  mediaMetric: MediaMetricInput;
};


export type MutationAddViewArgs = {
  mediaMetric: MediaMetricInput;
};


export type MutationCancelInvitationArgs = {
  invitationGUID: Scalars['ID'];
};


export type MutationCancelSpouseInviteArgs = {
  id: Scalars['ID'];
};


export type MutationChangeParticipantGroupRoleArgs = {
  groupParticipantId: Scalars['ID'];
  roleId: Scalars['ID'];
};


export type MutationContactLeaderArgs = {
  contactLeaderInput: ContactLeaderInput;
};


export type MutationCreateGroupArgs = {
  createGroupInput?: InputMaybe<CreateGroupInput>;
};


export type MutationCreateUserArgs = {
  userProfileInput?: InputMaybe<UserProfileInput>;
};


export type MutationCreateWayfinderAssessmentArgs = {
  userId: Scalars['ID'];
  version: Scalars['String'];
};


export type MutationCreateWayfinderUserArgs = {
  user: WayfinderUserInput;
};


export type MutationDeclineInvitationArgs = {
  invitationDetails?: InputMaybe<GroupInvitationDetails>;
};


export type MutationDeclineSpouseInviteArgs = {
  id: Scalars['ID'];
};


export type MutationDenyJoinRequestArgs = {
  joinRequestDetails?: InputMaybe<JoinRequestDetails>;
};


export type MutationGenerateCampActivitiesArgs = {
  eventParticipantId: Scalars['Int'];
};


export type MutationGenerateGoTripBadgesAndActivitiesArgs = {
  groupId: Scalars['Int'];
};


export type MutationGenerateGroupBadgesAndActivitiesArgs = {
  groupParticipantId: Scalars['Int'];
};


export type MutationGenerateGroupLeaderBadgeArgs = {
  participantId: Scalars['Int'];
};


export type MutationInviteMySpouseArgs = {
  spouseEmail?: InputMaybe<Scalars['String']>;
  spouseFirstName?: InputMaybe<Scalars['String']>;
  spouseId?: InputMaybe<Scalars['ID']>;
  spouseLastName?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};


export type MutationInviteNewGroupParticipantArgs = {
  groupInvitation: GroupInvitation;
};


export type MutationLeaveGroupArgs = {
  leaveGroupInput?: InputMaybe<LeaveGroupInput>;
};


export type MutationLogFeelingResponseArgs = {
  response?: InputMaybe<FeelingResponseInput>;
};


export type MutationLogTypeFormResponseArgs = {
  response?: InputMaybe<TypeFormResponseInput>;
  responseId?: InputMaybe<Scalars['String']>;
};


export type MutationPracticeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationPracticeGoalArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationRegisterParticipantsForGroupArgs = {
  registrationInput?: InputMaybe<Array<RegistrationInput>>;
};


export type MutationRemoveParticipantFromGroupArgs = {
  groupParticipantId: Scalars['ID'];
  removalReason?: InputMaybe<Scalars['String']>;
};


export type MutationRequestToJoinGroupArgs = {
  groupJoinRequestInfo: GroupJoinRequestInfo;
};


export type MutationResendResultsEmailArgs = {
  id: Scalars['ID'];
};


export type MutationResetEngagementsArgs = {
  userId: Scalars['ID'];
};


export type MutationSendAssessmentEmailArgs = {
  id: Scalars['ID'];
  resend: Scalars['Boolean'];
};


export type MutationSetBaptismArgs = {
  baptism?: InputMaybe<BaptismInput>;
};


export type MutationSetClosestSiteArgs = {
  closestSiteID?: InputMaybe<Scalars['Int']>;
};


export type MutationSetGroupParticipantEndDateArgs = {
  groupId: Scalars['ID'];
  participantId: Scalars['ID'];
};


export type MutationSetGroupPrivacyArgs = {
  availableOnline?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationSetGroupsEndDateArgs = {
  endDate?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationSetLifeStageArgs = {
  lifeStage?: InputMaybe<LifeStageInput>;
};


export type MutationSetNewUserDataArgs = {
  data?: InputMaybe<NewUserProfileInput>;
};


export type MutationSetSiteArgs = {
  siteId: Scalars['ID'];
};


export type MutationUpdateGroupArgs = {
  id: Scalars['ID'];
  updateGroupInput?: InputMaybe<UpdateGroupInput>;
};


export type MutationUpdateUserProfileArgs = {
  userId: Scalars['ID'];
  userProfileInput?: InputMaybe<UserProfileInput>;
};


export type MutationUpdateWayfinderAssessmentArgs = {
  assessmentInput: WayfinderAssessmentInput;
};

export type NewUserProfileInput = {
  address?: InputMaybe<AddressInput>;
  email: Scalars['String'];
  siteId: Scalars['ID'];
};

export type ObscuredGeolocation = {
  __typename?: 'ObscuredGeolocation';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Page = Media & {
  __typename?: 'Page';
  authors?: Maybe<Array<Author>>;
  body?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type ParentGroup = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  ministryId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ParseData = {
  __typename?: 'ParseData';
  Key?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};

export type ParseDataInput = {
  Key?: InputMaybe<Scalars['String']>;
  Value?: InputMaybe<Scalars['String']>;
};

export type PasswordInput = {
  newPassword: Scalars['String'];
  oldPassword?: InputMaybe<Scalars['String']>;
};

export type Perspective = Content & {
  __typename?: 'Perspective';
  body?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  number?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type PhoneNumberInput = {
  number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type Podcast = Media & {
  __typename?: 'Podcast';
  authors?: Maybe<Array<Author>>;
  category?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  episodes?: Maybe<Array<Episode>>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type Practice = {
  __typename?: 'Practice';
  _id?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  create?: Maybe<Array<Maybe<Practice>>>;
  createdAt?: Maybe<Scalars['Date']>;
  delete?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description?: Maybe<Scalars['String']>;
  examples?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};


export type PracticeCreateArgs = {
  params?: InputMaybe<PracticeCreateInput>;
};


export type PracticeDeleteArgs = {
  params?: InputMaybe<PracticeDeleteInput>;
};

export type PracticeCreateInput = {
  practices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PracticeDeleteInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PracticeGoal = {
  __typename?: 'PracticeGoal';
  _id?: Maybe<Scalars['ID']>;
  create?: Maybe<PracticeGoal>;
  createdAt?: Maybe<Scalars['Date']>;
  delete?: Maybe<Scalars['ID']>;
  practice?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  update?: Maybe<PracticeGoal>;
  updatedAt?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['String']>;
};


export type PracticeGoalCreateArgs = {
  params?: InputMaybe<PracticeGoalCreateInput>;
};


export type PracticeGoalUpdateArgs = {
  params?: InputMaybe<PracticeGoalUpdateInput>;
};

export type PracticeGoalCreateInput = {
  practice?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type PracticeGoalUpdateInput = {
  text?: InputMaybe<Scalars['String']>;
};

export type PracticeGoalsConnection = {
  __typename?: 'PracticeGoalsConnection';
  practiceGoals?: Maybe<Array<Maybe<PracticeGoal>>>;
};

export type PracticesConnection = {
  __typename?: 'PracticesConnection';
  practices?: Maybe<Array<Maybe<Practice>>>;
};

export type PracticesSortInput = {
  byAlphabetical?: InputMaybe<Scalars['Boolean']>;
};

export type Promo = Media & {
  __typename?: 'Promo';
  authors?: Maybe<Array<Author>>;
  category?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  targetAudience?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  ageGroups?: Maybe<Array<AgeGroups>>;
  albums?: Maybe<Array<Maybe<Album>>>;
  answers?: Maybe<Array<Maybe<WayfinderAnswer>>>;
  articles?: Maybe<Array<Maybe<Article>>>;
  assessment?: Maybe<WayfinderAssessment>;
  authors?: Maybe<Array<Maybe<Author>>>;
  backgroundCheckRules?: Maybe<Array<BackgroundCheckRule>>;
  camp: Camp;
  categories?: Maybe<Array<Maybe<Category>>>;
  closestSite?: Maybe<Site>;
  config?: Maybe<WayfinderConfig>;
  content?: Maybe<Array<Maybe<WayfinderContentBlock>>>;
  contentBlocks?: Maybe<Array<Maybe<ContentBlock>>>;
  currentUser?: Maybe<WayfinderUser>;
  demographics?: Maybe<Array<Demographic>>;
  eligibleMembers?: Maybe<Array<FamilyMember>>;
  emergencyContact?: Maybe<EmergencyContact>;
  episodes?: Maybe<Array<Maybe<Episode>>>;
  feelingResponses?: Maybe<Array<FeelingResponse>>;
  focuses?: Maybe<Array<Focus>>;
  getWayfinderAnswers?: Maybe<Array<WayfinderAnswer>>;
  getWayfinderAssessment?: Maybe<WayfinderAssessment>;
  getWayfinderConfig: WayfinderConfig;
  getWayfinderContentBlocks?: Maybe<Array<WayfinderContentBlock>>;
  getWayfinderQuestions?: Maybe<Array<WayfinderQuestion>>;
  getWayfinderUsers?: Maybe<Array<WayfinderUser>>;
  groups?: Maybe<Array<Group>>;
  invitation?: Maybe<Invitation>;
  lifeStages?: Maybe<Array<LifeStage>>;
  maritalStatuses?: Maybe<Array<MaritalStatus>>;
  medical?: Maybe<MedicalInformation>;
  meetingFrequencies?: Maybe<Array<MeetingFrequency>>;
  messages?: Maybe<Array<Maybe<Message>>>;
  pages?: Maybe<Array<Maybe<Page>>>;
  perspectives?: Maybe<Array<Maybe<Perspective>>>;
  podcasts?: Maybe<Array<Maybe<Podcast>>>;
  practices?: Maybe<PracticesConnection>;
  preloader?: Maybe<Scalars['Boolean']>;
  promos?: Maybe<Array<Maybe<Promo>>>;
  questions?: Maybe<Array<Maybe<WayfinderQuestion>>>;
  reservation: CampReservation;
  searchWidgets?: Maybe<Array<Maybe<SearchWidget>>>;
  series?: Maybe<Array<Maybe<Series>>>;
  signUps?: Maybe<Array<Maybe<SignUp>>>;
  site?: Maybe<Site>;
  sites?: Maybe<Array<Site>>;
  socialMediaPosts?: Maybe<Array<SocialMediaPost>>;
  songs?: Maybe<Array<Maybe<Song>>>;
  spouseInvite?: Maybe<SpouseInvite>;
  systemPages?: Maybe<Array<Maybe<SystemPage>>>;
  typeFormResponses?: Maybe<Array<TypeFormResponse>>;
  user?: Maybe<User>;
  users?: Maybe<Array<User>>;
  videos?: Maybe<Array<Maybe<Video>>>;
  volunteerRoles?: Maybe<Array<VolunteerRole>>;
  waiver?: Maybe<Waiver>;
};


export type QueryAlbumsArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryArticlesArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryAuthorsArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryCampArgs = {
  eventId?: InputMaybe<Scalars['Int']>;
};


export type QueryCategoriesArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryClosestSiteArgs = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  maxTravelTime?: InputMaybe<Scalars['Int']>;
};


export type QueryContentBlocksArgs = {
  filters?: InputMaybe<ContentBlockInput>;
};


export type QueryEligibleMembersArgs = {
  eventId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type QueryEmergencyContactArgs = {
  contactId?: InputMaybe<Scalars['Int']>;
  eventId?: InputMaybe<Scalars['Int']>;
};


export type QueryEpisodesArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryFocusesArgs = {
  category?: InputMaybe<Scalars['ID']>;
};


export type QueryGetWayfinderAnswersArgs = {
  version: Scalars['String'];
};


export type QueryGetWayfinderAssessmentArgs = {
  id: Scalars['ID'];
};


export type QueryGetWayfinderQuestionsArgs = {
  version?: InputMaybe<Scalars['String']>;
};


export type QueryGroupsArgs = {
  attributeIds?: InputMaybe<Array<Scalars['ID']>>;
  availableOnline?: InputMaybe<Scalars['Boolean']>;
  crossroadsHosted?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Int']>;
  expired?: InputMaybe<Scalars['Boolean']>;
  groupAttributeIds?: InputMaybe<Array<Scalars['ID']>>;
  groupParticipantIds?: InputMaybe<Array<Scalars['ID']>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isParent?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  ministry?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  offset?: InputMaybe<Scalars['Int']>;
  primaryContactParticipantId?: InputMaybe<Scalars['ID']>;
  types?: InputMaybe<Array<InputMaybe<GroupTypeName>>>;
};


export type QueryInvitationArgs = {
  invitationGUID: Scalars['ID'];
};


export type QueryMedicalArgs = {
  contactId?: InputMaybe<Scalars['Int']>;
  eventId?: InputMaybe<Scalars['Int']>;
};


export type QueryMessagesArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryPagesArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryPerspectivesArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryPodcastsArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryPracticesArgs = {
  sort?: InputMaybe<PracticesSortInput>;
};


export type QueryPromosArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryReservationArgs = {
  contactId?: InputMaybe<Scalars['Int']>;
  eventId?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchWidgetsArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerySeriesArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerySignUpsArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerySiteArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QuerySitesArgs = {
  availableOnline?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerySocialMediaPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sources?: InputMaybe<Array<Scalars['String']>>;
  usernames?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerySongsArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerySpouseInviteArgs = {
  id?: InputMaybe<Scalars['ID']>;
  invitedSpouseId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QuerySystemPagesArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryUsersArgs = {
  addressIds?: InputMaybe<Array<Scalars['Int']>>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  householdIds?: InputMaybe<Array<Scalars['Int']>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  participantIds?: InputMaybe<Array<Scalars['Int']>>;
  showOnMap?: InputMaybe<Scalars['Boolean']>;
};


export type QueryVideosArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryVolunteerRolesArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryWaiverArgs = {
  contactId?: InputMaybe<Scalars['Int']>;
  eventId?: InputMaybe<Scalars['Int']>;
};

export type RecurringGift = {
  __typename?: 'RecurringGift';
  active?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};

export type RegistrationInput = {
  contactId: Scalars['ID'];
  email: Scalars['String'];
  groupId: Scalars['ID'];
  groupName: Scalars['String'];
  isWaitlist: Scalars['Boolean'];
  lastName: Scalars['String'];
  nickName: Scalars['String'];
  participantId: Scalars['ID'];
  roleId: Scalars['ID'];
  siteName: Scalars['String'];
};

export type Relationship = {
  __typename?: 'Relationship';
  endDate?: Maybe<Scalars['Float']>;
  relationshipId: Scalars['ID'];
  startDate: Scalars['Float'];
  user: RelationshipUser;
};

export type RelationshipUser = IUser & {
  __typename?: 'RelationshipUser';
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Group>>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  participantId: Scalars['ID'];
};


export type RelationshipUserGroupsArgs = {
  endDate?: InputMaybe<Scalars['Int']>;
  expired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  roleTypes?: InputMaybe<Array<Scalars['Int']>>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SearchWidget = Content & {
  __typename?: 'SearchWidget';
  body?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type SecurityRole = {
  __typename?: 'SecurityRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Series = Media & {
  __typename?: 'Series';
  authors?: Maybe<Array<Author>>;
  category?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  messages?: Maybe<Array<Maybe<Message>>>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type SignUp = {
  __typename?: 'SignUp';
  body?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  existingMemberContent?: Maybe<Scalars['String']>;
  fullContent?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  meta?: Maybe<Meta>;
  opportunityId?: Maybe<Scalars['ID']>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  successContent?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  waitListContent?: Maybe<Scalars['String']>;
  waitListSuccessContent?: Maybe<Scalars['String']>;
};

export type Site = {
  __typename?: 'Site';
  address?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  geolocation?: Maybe<Geolocation>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isPhysicalLocation: Scalars['Boolean'];
  mapImageUrl?: Maybe<Scalars['String']>;
  mapUrl?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  name: Scalars['String'];
  openHours?: Maybe<Scalars['String']>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  serviceTimes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type SiteInput = {
  id: Scalars['Int'];
};

export type SocialMediaPost = {
  __typename?: 'SocialMediaPost';
  caption?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mediaType?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  username?: Maybe<Scalars['String']>;
};

export type Song = Media & {
  __typename?: 'Song';
  album?: Maybe<Album>;
  artist?: Maybe<Scalars['String']>;
  authors?: Maybe<Array<Author>>;
  category?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  lyrics?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type Spouse = IUser & {
  __typename?: 'Spouse';
  dateOfBirth?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Group>>;
  homePhone?: Maybe<Scalars['String']>;
  householdId?: Maybe<Scalars['Int']>;
  householdPosition?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<MaritalStatus>;
  mobilePhone?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  participantId: Scalars['ID'];
  phoneNumbers?: Maybe<Array<PhoneNumber>>;
  site?: Maybe<Site>;
};


export type SpouseGroupsArgs = {
  endDate?: InputMaybe<Scalars['Int']>;
  expired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  roleTypes?: InputMaybe<Array<Scalars['Int']>>;
  types?: InputMaybe<Array<InputMaybe<GroupTypeName>>>;
};

export type SpouseInvite = {
  __typename?: 'SpouseInvite';
  cancelledDate?: Maybe<Scalars['Float']>;
  date: Scalars['Float'];
  declinedDate?: Maybe<Scalars['Float']>;
  expirationDate: Scalars['Float'];
  id: Scalars['ID'];
  invitedSpouse?: Maybe<Spouse>;
  linkedDate?: Maybe<Scalars['Float']>;
  user?: Maybe<Spouse>;
};

export type SystemPage = Content & {
  __typename?: 'SystemPage';
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type TypeFormResponse = {
  __typename?: 'TypeFormResponse';
  id: Scalars['ID'];
  ref: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type TypeFormResponseInput = {
  fields: Array<InputTypeFormResponse>;
  formId: Scalars['String'];
  landedAt?: InputMaybe<Scalars['String']>;
  submittedAt?: InputMaybe<Scalars['String']>;
};

export type UpdateGroupInput = {
  additionalServices?: InputMaybe<AdditionalServicesInput>;
  ageGroups?: InputMaybe<Array<GroupAttributeInput>>;
  availableOnline?: InputMaybe<Scalars['Boolean']>;
  capacity?: InputMaybe<Scalars['Int']>;
  childcareAvailable?: InputMaybe<Scalars['Boolean']>;
  customRegistrationLink?: InputMaybe<Scalars['String']>;
  demographics?: InputMaybe<Array<GroupAttributeInput>>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  focuses?: InputMaybe<Array<GroupAttributeInput>>;
  isLeader?: InputMaybe<Scalars['Boolean']>;
  isPrimaryContact?: InputMaybe<Scalars['Boolean']>;
  journeyActive?: InputMaybe<Scalars['Boolean']>;
  kidsWelcome?: InputMaybe<Scalars['Boolean']>;
  leaders?: InputMaybe<Array<Scalars['String']>>;
  meetingSchedule?: InputMaybe<MeetingScheduleInput>;
  meetsOnline?: InputMaybe<Scalars['Boolean']>;
  ministryId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  offsiteMeetingAddress?: InputMaybe<GroupAddressInput>;
  ongoing?: InputMaybe<Scalars['Boolean']>;
  parentGroup?: InputMaybe<ParentGroup>;
  primaryContactId?: InputMaybe<Scalars['ID']>;
  registrationNotRequired?: InputMaybe<Scalars['Boolean']>;
  siteId?: InputMaybe<Scalars['ID']>;
  staffContact?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Float']>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type User = IUser & {
  __typename?: 'User';
  activities?: Maybe<Array<Maybe<Engagement>>>;
  addresses?: Maybe<Array<Address>>;
  badges?: Maybe<Array<Maybe<Engagement>>>;
  baptism?: Maybe<Baptism>;
  camps?: Maybe<Array<CampReservation>>;
  closestSite?: Maybe<Site>;
  connectUrl?: Maybe<Scalars['String']>;
  contactLoginFraction?: Maybe<Fraction>;
  created_at?: Maybe<Scalars['Float']>;
  dateOfBirth?: Maybe<Scalars['Float']>;
  demographic?: Maybe<Array<Scalars['String']>>;
  donations?: Maybe<Array<Donation>>;
  email?: Maybe<Scalars['String']>;
  emailHash?: Maybe<Scalars['String']>;
  engagements?: Maybe<Array<Maybe<Engagement>>>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  geolocation?: Maybe<Geolocation>;
  grade?: Maybe<Scalars['ID']>;
  groupLeaderStatusId?: Maybe<Scalars['ID']>;
  groups?: Maybe<Array<Group>>;
  homePhone?: Maybe<Scalars['String']>;
  householdFraction?: Maybe<Fraction>;
  householdId?: Maybe<Scalars['Int']>;
  householdMembers?: Maybe<Array<Maybe<HouseholdMember>>>;
  householdPosition?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lifeStage?: Maybe<LifeStage>;
  maritalStatus?: Maybe<MaritalStatus>;
  mediaMetrics?: Maybe<MediaMetrics>;
  mobilePhone?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  obscuredGeolocation?: Maybe<ObscuredGeolocation>;
  participantId: Scalars['ID'];
  pendingJoinGroupRequests?: Maybe<Array<Maybe<JoinGroupRequest>>>;
  personalInformationFraction?: Maybe<Fraction>;
  phoneNumbers?: Maybe<Array<PhoneNumber>>;
  practiceGoalsConnection?: Maybe<PracticeGoalsConnection>;
  practicesConnection?: Maybe<PracticesConnection>;
  recurringGifts?: Maybe<Array<RecurringGift>>;
  redFlagNotes?: Maybe<Scalars['String']>;
  relationships?: Maybe<Array<Relationship>>;
  securityRoles?: Maybe<Array<Maybe<SecurityRole>>>;
  showOnMap?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Site>;
  specialNeeds?: Maybe<Scalars['Boolean']>;
  spouse?: Maybe<Spouse>;
};


export type UserDonationsArgs = {
  filters?: InputMaybe<DonationArgs>;
};


export type UserGroupsArgs = {
  endDate?: InputMaybe<Scalars['Int']>;
  expired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  includePrimaryContactGroups?: InputMaybe<Scalars['Boolean']>;
  roleTypes?: InputMaybe<Array<Scalars['Int']>>;
  types?: InputMaybe<Array<InputMaybe<GroupTypeName>>>;
};


export type UserHouseholdMembersArgs = {
  types?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type UserMediaMetricsArgs = {
  numberOf?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Float']>;
};


export type UserPracticeGoalsConnectionArgs = {
  select?: InputMaybe<UserPracticeGoalSelectInput>;
  sort?: InputMaybe<UserPracticeGoalSortInput>;
};


export type UserPracticesConnectionArgs = {
  sort?: InputMaybe<UserPracticeSortInput>;
};


export type UserRecurringGiftsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  includeCogiver?: InputMaybe<Scalars['Boolean']>;
  programs?: InputMaybe<Array<Scalars['Int']>>;
};

export type UserFeelingResponse = {
  __typename?: 'UserFeelingResponse';
  dateTime?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
};

export type UserPracticeGoalSelectInput = {
  practice?: InputMaybe<Scalars['String']>;
};

export type UserPracticeGoalSortInput = {
  createdAtDesc?: InputMaybe<Scalars['Boolean']>;
};

export type UserPracticeSortInput = {
  createdAtDesc?: InputMaybe<Scalars['Boolean']>;
};

export type UserProfileInput = {
  addresses?: InputMaybe<Array<AddressInput>>;
  dateOfBirth?: InputMaybe<Scalars['Float']>;
  email?: InputMaybe<EmailInput>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['ID']>;
  householdId?: InputMaybe<Scalars['ID']>;
  householdPosition?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  maritalStatus?: InputMaybe<MaritalStatusInput>;
  nickName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<PasswordInput>;
  phoneNumbers?: InputMaybe<Array<PhoneNumberInput>>;
  site?: InputMaybe<SiteInput>;
  specialNeeds?: InputMaybe<Scalars['Boolean']>;
};

export type Video = Media & {
  __typename?: 'Video';
  authors?: Maybe<Array<Author>>;
  category?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  date?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  distributionChannels?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Int']>;
  meta?: Maybe<Meta>;
  qualifiedUrl?: Maybe<Scalars['String']>;
  searchExcluded: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  subTitles?: Maybe<Scalars['String']>;
  subTitlesFileUrl?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type VolunteerRole = {
  __typename?: 'VolunteerRole';
  activationDate?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  additionalInformation?: Maybe<Scalars['String']>;
  callToActionUrl?: Maybe<Scalars['String']>;
  community?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType: Scalars['String'];
  description: Scalars['String'];
  expirationDate?: Maybe<Scalars['String']>;
  frequency: Scalars['String'];
  id: Scalars['ID'];
  ministry?: Maybe<Array<Scalars['String']>>;
  servingArea: Scalars['String'];
  servingTime?: Maybe<Array<Scalars['String']>>;
  servingType: Scalars['String'];
  skillsAndInterests?: Maybe<Array<Scalars['String']>>;
  volunteerRole: Scalars['String'];
};

export type Waiver = {
  __typename?: 'Waiver';
  accepted?: Maybe<Scalars['Boolean']>;
  signee?: Maybe<Scalars['Int']>;
  waiverId: Scalars['ID'];
  waiverName?: Maybe<Scalars['String']>;
  waiverRequired?: Maybe<Scalars['Boolean']>;
  waiverText?: Maybe<Scalars['String']>;
};

export type WayfinderAnswer = {
  __typename?: 'WayfinderAnswer';
  _id: Scalars['ID'];
  answertext?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};

export type WayfinderAssessment = {
  __typename?: 'WayfinderAssessment';
  _id: Scalars['ID'];
  assessmentdate?: Maybe<Scalars['String']>;
  completedDate?: Maybe<Scalars['String']>;
  congregation?: Maybe<Scalars['String']>;
  congregationid?: Maybe<Scalars['Int']>;
  contactMeEmailSendDate?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  groupMembership?: Maybe<WayfinderGroupMembership>;
  lastname?: Maybe<Scalars['String']>;
  learned?: Maybe<Scalars['String']>;
  openResponse1?: Maybe<Scalars['String']>;
  openResponse2?: Maybe<Scalars['String']>;
  openResponse3?: Maybe<Scalars['String']>;
  openResponse4?: Maybe<Scalars['String']>;
  reflectionEmailSendDate?: Maybe<Scalars['String']>;
  reflectionEmailSent?: Maybe<Scalars['Boolean']>;
  responses?: Maybe<Array<Maybe<WayfinderResponse>>>;
  resultsEmailSendDate?: Maybe<Scalars['String']>;
  resultsEmailSent?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  userid?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type WayfinderAssessmentEmailStatus = {
  __typename?: 'WayfinderAssessmentEmailStatus';
  message?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
};

export type WayfinderAssessmentInput = {
  _id: Scalars['ID'];
  assessmentdate?: InputMaybe<Scalars['String']>;
  completedDate?: InputMaybe<Scalars['String']>;
  congregation?: InputMaybe<Scalars['String']>;
  congregationid?: InputMaybe<Scalars['Int']>;
  contactMeEmailSendDate?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  groupMembership?: InputMaybe<WayfinderGroupMembershipInput>;
  lastname?: InputMaybe<Scalars['String']>;
  learned?: InputMaybe<Scalars['String']>;
  openResponse1?: InputMaybe<Scalars['String']>;
  openResponse2?: InputMaybe<Scalars['String']>;
  openResponse3?: InputMaybe<Scalars['String']>;
  openResponse4?: InputMaybe<Scalars['String']>;
  reflectionEmailSendDate?: InputMaybe<Scalars['String']>;
  reflectionEmailSent?: InputMaybe<Scalars['Boolean']>;
  responses?: InputMaybe<Array<InputMaybe<WayfinderResponseInput>>>;
  resultsEmailSendDate?: InputMaybe<Scalars['String']>;
  resultsEmailSent?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  userid?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

export type WayfinderConfig = {
  __typename?: 'WayfinderConfig';
  _id: Scalars['ID'];
  currentversion?: Maybe<Scalars['String']>;
  groupContactEmail?: Maybe<Scalars['String']>;
  openforbusiness?: Maybe<Scalars['Boolean']>;
};

export type WayfinderContentBlock = {
  __typename?: 'WayfinderContentBlock';
  _id: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type WayfinderGroupMembership = {
  __typename?: 'WayfinderGroupMembership';
  canContact?: Maybe<Scalars['Boolean']>;
  isGroupLeader?: Maybe<Scalars['Boolean']>;
  isGroupMember?: Maybe<Scalars['Boolean']>;
};

export type WayfinderGroupMembershipInput = {
  canContact?: InputMaybe<Scalars['Boolean']>;
  isGroupLeader?: InputMaybe<Scalars['Boolean']>;
  isGroupMember?: InputMaybe<Scalars['Boolean']>;
};

export type WayfinderQuestion = {
  __typename?: 'WayfinderQuestion';
  _id: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
  categoryweight?: Maybe<Scalars['Float']>;
  questiongrouporder?: Maybe<Scalars['Int']>;
  questionid?: Maybe<Scalars['String']>;
  questiontext?: Maybe<Scalars['String']>;
  questionweight?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['String']>;
};

export type WayfinderQuestionInput = {
  _id: Scalars['ID'];
  category?: InputMaybe<Scalars['String']>;
  categoryweight?: InputMaybe<Scalars['Float']>;
  questiongrouporder?: InputMaybe<Scalars['Int']>;
  questionid?: InputMaybe<Scalars['String']>;
  questiontext?: InputMaybe<Scalars['String']>;
  questionweight?: InputMaybe<Scalars['Float']>;
  version?: InputMaybe<Scalars['String']>;
};

export type WayfinderResponse = {
  __typename?: 'WayfinderResponse';
  _id: Scalars['ID'];
  answertext?: Maybe<Scalars['String']>;
  answervalue?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  categoryweight?: Maybe<Scalars['Float']>;
  enddate?: Maybe<Scalars['String']>;
  question?: Maybe<WayfinderQuestion>;
  questionid?: Maybe<Scalars['String']>;
  questiontext?: Maybe<Scalars['String']>;
  questionweight?: Maybe<Scalars['Float']>;
  sortorder?: Maybe<Scalars['Int']>;
  startdate?: Maybe<Scalars['String']>;
};

export type WayfinderResponseInput = {
  _id: Scalars['ID'];
  answertext?: InputMaybe<Scalars['String']>;
  answervalue?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  categoryweight?: InputMaybe<Scalars['Float']>;
  enddate?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<WayfinderQuestionInput>;
  questionid?: InputMaybe<Scalars['String']>;
  questiontext?: InputMaybe<Scalars['String']>;
  questionweight?: InputMaybe<Scalars['Float']>;
  sortorder?: InputMaybe<Scalars['Int']>;
  startdate?: InputMaybe<Scalars['String']>;
};

export type WayfinderUser = {
  __typename?: 'WayfinderUser';
  _id: Scalars['ID'];
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
};

export type WayfinderUserInput = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
};

export type AnswerFragment = { __typename?: 'WayfinderAnswer', _id: string, answertext?: string | null, points?: number | null, sequence?: number | null, version?: string | null };

export type GetWayfinderAnswersQueryVariables = Exact<{
  version: Scalars['String'];
}>;


export type GetWayfinderAnswersQuery = { __typename?: 'Query', getWayfinderAnswers?: Array<{ __typename?: 'WayfinderAnswer', _id: string, answertext?: string | null, points?: number | null, sequence?: number | null, version?: string | null }> | null };

export type AssessmentFragment = { __typename?: 'WayfinderAssessment', _id: string, completedDate?: string | null, congregation?: string | null, congregationid?: number | null, email?: string | null, firstname?: string | null, lastname?: string | null, learned?: string | null, reflectionEmailSendDate?: string | null, reflectionEmailSent?: boolean | null, resultsEmailSendDate?: string | null, resultsEmailSent?: boolean | null, startDate?: string | null, uid?: string | null, userid?: string | null, version?: string | null, assessmentdate?: string | null, openResponse1?: string | null, openResponse2?: string | null, openResponse3?: string | null, openResponse4?: string | null, groupMembership?: { __typename?: 'WayfinderGroupMembership', isGroupMember?: boolean | null, isGroupLeader?: boolean | null, canContact?: boolean | null } | null, responses?: Array<{ __typename?: 'WayfinderResponse', _id: string, questiontext?: string | null, questionweight?: number | null, category?: string | null, categoryweight?: number | null, answertext?: string | null, answervalue?: number | null, startdate?: string | null, enddate?: string | null, sortorder?: number | null, questionid?: string | null, question?: { __typename?: 'WayfinderQuestion', _id: string, category?: string | null, questiongrouporder?: number | null, questionid?: string | null, version?: string | null, questiontext?: string | null, categoryweight?: number | null, questionweight?: number | null } | null } | null> | null };

export type CreateWayfinderAssessmentMutationVariables = Exact<{
  userId: Scalars['ID'];
  version: Scalars['String'];
}>;


export type CreateWayfinderAssessmentMutation = { __typename?: 'Mutation', createWayfinderAssessment: { __typename?: 'WayfinderAssessment', _id: string, completedDate?: string | null, congregation?: string | null, congregationid?: number | null, email?: string | null, firstname?: string | null, lastname?: string | null, learned?: string | null, reflectionEmailSendDate?: string | null, reflectionEmailSent?: boolean | null, resultsEmailSendDate?: string | null, resultsEmailSent?: boolean | null, startDate?: string | null, uid?: string | null, userid?: string | null, version?: string | null, assessmentdate?: string | null, openResponse1?: string | null, openResponse2?: string | null, openResponse3?: string | null, openResponse4?: string | null, groupMembership?: { __typename?: 'WayfinderGroupMembership', isGroupMember?: boolean | null, isGroupLeader?: boolean | null, canContact?: boolean | null } | null, responses?: Array<{ __typename?: 'WayfinderResponse', _id: string, questiontext?: string | null, questionweight?: number | null, category?: string | null, categoryweight?: number | null, answertext?: string | null, answervalue?: number | null, startdate?: string | null, enddate?: string | null, sortorder?: number | null, questionid?: string | null, question?: { __typename?: 'WayfinderQuestion', _id: string, category?: string | null, questiongrouporder?: number | null, questionid?: string | null, version?: string | null, questiontext?: string | null, categoryweight?: number | null, questionweight?: number | null } | null } | null> | null } };

export type UpdateWayfinderAssessmentMutationVariables = Exact<{
  assessmentInput: WayfinderAssessmentInput;
}>;


export type UpdateWayfinderAssessmentMutation = { __typename?: 'Mutation', updateWayfinderAssessment: { __typename?: 'WayfinderAssessment', _id: string, completedDate?: string | null, congregation?: string | null, congregationid?: number | null, email?: string | null, firstname?: string | null, lastname?: string | null, learned?: string | null, reflectionEmailSendDate?: string | null, reflectionEmailSent?: boolean | null, resultsEmailSendDate?: string | null, resultsEmailSent?: boolean | null, startDate?: string | null, uid?: string | null, userid?: string | null, version?: string | null, assessmentdate?: string | null, openResponse1?: string | null, openResponse2?: string | null, openResponse3?: string | null, openResponse4?: string | null, groupMembership?: { __typename?: 'WayfinderGroupMembership', isGroupMember?: boolean | null, isGroupLeader?: boolean | null, canContact?: boolean | null } | null, responses?: Array<{ __typename?: 'WayfinderResponse', _id: string, questiontext?: string | null, questionweight?: number | null, category?: string | null, categoryweight?: number | null, answertext?: string | null, answervalue?: number | null, startdate?: string | null, enddate?: string | null, sortorder?: number | null, questionid?: string | null, question?: { __typename?: 'WayfinderQuestion', _id: string, category?: string | null, questiongrouporder?: number | null, questionid?: string | null, version?: string | null, questiontext?: string | null, categoryweight?: number | null, questionweight?: number | null } | null } | null> | null } };

export type ConfigFragment = { __typename?: 'WayfinderConfig', _id: string, currentversion?: string | null, openforbusiness?: boolean | null };

export type GetWayfinderConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWayfinderConfigQuery = { __typename?: 'Query', getWayfinderConfig: { __typename?: 'WayfinderConfig', _id: string, currentversion?: string | null, openforbusiness?: boolean | null } };

export type ContentFragment = { __typename?: 'WayfinderContentBlock', _id: string, category?: string | null, content?: string | null, className?: string | null, title?: string | null, type?: string | null };

export type GetWayfinderContentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWayfinderContentQuery = { __typename?: 'Query', getWayfinderContentBlocks?: Array<{ __typename?: 'WayfinderContentBlock', _id: string, category?: string | null, content?: string | null, className?: string | null, title?: string | null, type?: string | null }> | null };

export type GroupMembershipFragment = { __typename?: 'WayfinderGroupMembership', isGroupMember?: boolean | null, isGroupLeader?: boolean | null, canContact?: boolean | null };

export type QuestionFragment = { __typename?: 'WayfinderQuestion', _id: string, category?: string | null, questiongrouporder?: number | null, questionid?: string | null, version?: string | null, questiontext?: string | null, categoryweight?: number | null, questionweight?: number | null };

export type GetWayfinderQuestionsQueryVariables = Exact<{
  version: Scalars['String'];
}>;


export type GetWayfinderQuestionsQuery = { __typename?: 'Query', getWayfinderQuestions?: Array<{ __typename?: 'WayfinderQuestion', _id: string, category?: string | null, questiongrouporder?: number | null, questionid?: string | null, version?: string | null, questiontext?: string | null, categoryweight?: number | null, questionweight?: number | null }> | null };

export type ResponseFragment = { __typename?: 'WayfinderResponse', _id: string, questiontext?: string | null, questionweight?: number | null, category?: string | null, categoryweight?: number | null, answertext?: string | null, answervalue?: number | null, startdate?: string | null, enddate?: string | null, sortorder?: number | null, questionid?: string | null, question?: { __typename?: 'WayfinderQuestion', _id: string, category?: string | null, questiongrouporder?: number | null, questionid?: string | null, version?: string | null, questiontext?: string | null, categoryweight?: number | null, questionweight?: number | null } | null };

export type UserFragment = { __typename?: 'WayfinderUser', _id: string, firstname?: string | null, lastname?: string | null };

export type CreateWayfinderUserMutationVariables = Exact<{
  user: WayfinderUserInput;
}>;


export type CreateWayfinderUserMutation = { __typename?: 'Mutation', createWayfinderUser: { __typename?: 'WayfinderUser', _id: string, firstname?: string | null, lastname?: string | null } };

export type AssessmentEmailStatusFragment = { __typename?: 'WayfinderAssessmentEmailStatus', sent?: boolean | null, message?: string | null };

export type SendWayfinderAssessmentEmailMutationVariables = Exact<{
  id: Scalars['ID'];
  resend: Scalars['Boolean'];
}>;


export type SendWayfinderAssessmentEmailMutation = { __typename?: 'Mutation', sendAssessmentEmail: { __typename?: 'WayfinderAssessmentEmailStatus', sent?: boolean | null, message?: string | null } };

export type GetAnswersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAnswersQuery = { __typename?: 'Query', answers?: Array<{ __typename?: 'WayfinderAnswer', _id: string, answertext?: string | null, points?: number | null, sequence?: number | null, version?: string | null } | null> | null };

export type GetAssessmentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAssessmentQuery = { __typename?: 'Query', assessment?: { __typename?: 'WayfinderAssessment', _id: string, completedDate?: string | null, congregation?: string | null, congregationid?: number | null, email?: string | null, firstname?: string | null, lastname?: string | null, learned?: string | null, reflectionEmailSendDate?: string | null, reflectionEmailSent?: boolean | null, resultsEmailSendDate?: string | null, resultsEmailSent?: boolean | null, startDate?: string | null, uid?: string | null, userid?: string | null, version?: string | null, assessmentdate?: string | null, openResponse1?: string | null, openResponse2?: string | null, openResponse3?: string | null, openResponse4?: string | null, groupMembership?: { __typename?: 'WayfinderGroupMembership', isGroupMember?: boolean | null, isGroupLeader?: boolean | null, canContact?: boolean | null } | null, responses?: Array<{ __typename?: 'WayfinderResponse', _id: string, questiontext?: string | null, questionweight?: number | null, category?: string | null, categoryweight?: number | null, answertext?: string | null, answervalue?: number | null, startdate?: string | null, enddate?: string | null, sortorder?: number | null, questionid?: string | null, question?: { __typename?: 'WayfinderQuestion', _id: string, category?: string | null, questiongrouporder?: number | null, questionid?: string | null, version?: string | null, questiontext?: string | null, categoryweight?: number | null, questionweight?: number | null } | null } | null> | null } | null };

export type GetConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfigQuery = { __typename?: 'Query', config?: { __typename?: 'WayfinderConfig', _id: string, currentversion?: string | null, openforbusiness?: boolean | null } | null };

export type GetContentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContentQuery = { __typename?: 'Query', content?: Array<{ __typename?: 'WayfinderContentBlock', _id: string, category?: string | null, content?: string | null, className?: string | null, title?: string | null, type?: string | null } | null> | null };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'WayfinderUser', _id: string, firstname?: string | null, lastname?: string | null } | null };

export type GetQuestionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuestionsQuery = { __typename?: 'Query', questions?: Array<{ __typename?: 'WayfinderQuestion', _id: string, category?: string | null, questiongrouporder?: number | null, questionid?: string | null, version?: string | null, questiontext?: string | null, categoryweight?: number | null, questionweight?: number | null } | null> | null };

export type GetPreloaderQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPreloaderQuery = { __typename?: 'Query', preloader?: boolean | null };

export const AnswerFragmentDoc = gql`
    fragment Answer on WayfinderAnswer {
  _id
  answertext
  points
  sequence
  version
}
    `;
export const GroupMembershipFragmentDoc = gql`
    fragment GroupMembership on WayfinderGroupMembership {
  isGroupMember
  isGroupLeader
  canContact
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on WayfinderQuestion {
  _id
  category
  questiongrouporder
  questionid
  version
  questiontext
  categoryweight
  questionweight
}
    `;
export const ResponseFragmentDoc = gql`
    fragment Response on WayfinderResponse {
  _id
  question {
    ...Question
  }
  questiontext
  questionweight
  category
  categoryweight
  answertext
  answervalue
  startdate
  enddate
  sortorder
  questionid
}
    ${QuestionFragmentDoc}`;
export const AssessmentFragmentDoc = gql`
    fragment Assessment on WayfinderAssessment {
  _id
  completedDate
  congregation
  congregationid
  email
  firstname
  lastname
  learned
  reflectionEmailSendDate
  reflectionEmailSent
  resultsEmailSendDate
  resultsEmailSent
  startDate
  uid
  groupMembership {
    ...GroupMembership
  }
  userid
  version
  assessmentdate
  responses {
    ...Response
  }
  openResponse1
  openResponse2
  openResponse3
  openResponse4
}
    ${GroupMembershipFragmentDoc}
${ResponseFragmentDoc}`;
export const ConfigFragmentDoc = gql`
    fragment Config on WayfinderConfig {
  _id
  currentversion
  openforbusiness
}
    `;
export const ContentFragmentDoc = gql`
    fragment Content on WayfinderContentBlock {
  _id
  category
  content
  className
  title
  type
}
    `;
export const UserFragmentDoc = gql`
    fragment User on WayfinderUser {
  _id
  firstname
  lastname
}
    `;
export const AssessmentEmailStatusFragmentDoc = gql`
    fragment AssessmentEmailStatus on WayfinderAssessmentEmailStatus {
  sent
  message
}
    `;
export const GetWayfinderAnswersDocument = gql`
    query GetWayfinderAnswers($version: String!) {
  getWayfinderAnswers(version: $version) {
    ...Answer
  }
}
    ${AnswerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetWayfinderAnswersGQL extends Apollo.Query<GetWayfinderAnswersQuery, GetWayfinderAnswersQueryVariables> {
    document = GetWayfinderAnswersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateWayfinderAssessmentDocument = gql`
    mutation CreateWayfinderAssessment($userId: ID!, $version: String!) {
  createWayfinderAssessment(userId: $userId, version: $version) {
    ...Assessment
  }
}
    ${AssessmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateWayfinderAssessmentGQL extends Apollo.Mutation<CreateWayfinderAssessmentMutation, CreateWayfinderAssessmentMutationVariables> {
    document = CreateWayfinderAssessmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateWayfinderAssessmentDocument = gql`
    mutation UpdateWayfinderAssessment($assessmentInput: WayfinderAssessmentInput!) {
  updateWayfinderAssessment(assessmentInput: $assessmentInput) {
    ...Assessment
  }
}
    ${AssessmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateWayfinderAssessmentGQL extends Apollo.Mutation<UpdateWayfinderAssessmentMutation, UpdateWayfinderAssessmentMutationVariables> {
    document = UpdateWayfinderAssessmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetWayfinderConfigDocument = gql`
    query GetWayfinderConfig {
  getWayfinderConfig {
    ...Config
  }
}
    ${ConfigFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetWayfinderConfigGQL extends Apollo.Query<GetWayfinderConfigQuery, GetWayfinderConfigQueryVariables> {
    document = GetWayfinderConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetWayfinderContentDocument = gql`
    query GetWayfinderContent {
  getWayfinderContentBlocks {
    ...Content
  }
}
    ${ContentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetWayfinderContentGQL extends Apollo.Query<GetWayfinderContentQuery, GetWayfinderContentQueryVariables> {
    document = GetWayfinderContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetWayfinderQuestionsDocument = gql`
    query GetWayfinderQuestions($version: String!) {
  getWayfinderQuestions(version: $version) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetWayfinderQuestionsGQL extends Apollo.Query<GetWayfinderQuestionsQuery, GetWayfinderQuestionsQueryVariables> {
    document = GetWayfinderQuestionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateWayfinderUserDocument = gql`
    mutation CreateWayfinderUser($user: WayfinderUserInput!) {
  createWayfinderUser(user: $user) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateWayfinderUserGQL extends Apollo.Mutation<CreateWayfinderUserMutation, CreateWayfinderUserMutationVariables> {
    document = CreateWayfinderUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendWayfinderAssessmentEmailDocument = gql`
    mutation SendWayfinderAssessmentEmail($id: ID!, $resend: Boolean!) {
  sendAssessmentEmail(id: $id, resend: $resend) {
    ...AssessmentEmailStatus
  }
}
    ${AssessmentEmailStatusFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SendWayfinderAssessmentEmailGQL extends Apollo.Mutation<SendWayfinderAssessmentEmailMutation, SendWayfinderAssessmentEmailMutationVariables> {
    document = SendWayfinderAssessmentEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAnswersDocument = gql`
    query getAnswers {
  answers @client {
    ...Answer
  }
}
    ${AnswerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAnswersGQL extends Apollo.Query<GetAnswersQuery, GetAnswersQueryVariables> {
    document = GetAnswersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAssessmentDocument = gql`
    query getAssessment {
  assessment @client {
    ...Assessment
  }
}
    ${AssessmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAssessmentGQL extends Apollo.Query<GetAssessmentQuery, GetAssessmentQueryVariables> {
    document = GetAssessmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetConfigDocument = gql`
    query getConfig {
  config @client {
    ...Config
  }
}
    ${ConfigFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConfigGQL extends Apollo.Query<GetConfigQuery, GetConfigQueryVariables> {
    document = GetConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContentDocument = gql`
    query getContent {
  content @client {
    ...Content
  }
}
    ${ContentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContentGQL extends Apollo.Query<GetContentQuery, GetContentQueryVariables> {
    document = GetContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  currentUser @client {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCurrentUserGQL extends Apollo.Query<GetCurrentUserQuery, GetCurrentUserQueryVariables> {
    document = GetCurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetQuestionsDocument = gql`
    query getQuestions {
  questions @client {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetQuestionsGQL extends Apollo.Query<GetQuestionsQuery, GetQuestionsQueryVariables> {
    document = GetQuestionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPreloaderDocument = gql`
    query getPreloader {
  preloader @client
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPreloaderGQL extends Apollo.Query<GetPreloaderQuery, GetPreloaderQueryVariables> {
    document = GetPreloaderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }