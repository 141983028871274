<div class="jumbotron jumbotron-xl" style="background-image: url('https://s3.amazonaws.com/crds-cms-uploads/img/graphics/mountain-bkgrd-wayfinder.jpg'); ">
  <div class="container ">
    <div class="row ">
      <div *ngIf="state.GetOpenForBusiness() " class="col-xs-12 col-md-8 col-md-offset-2 ">
        <app-fire-content-block id="HomepageJumbotronText"></app-fire-content-block>
        <div class="btn-group btn-block-mobile">
          <div class="text-center">
            <button class="btn btn-blue btn-block btn-lg " role="button " (click)="takeAssesment() " data-automation-id="jumbotronTakeAssesmentBtn">Enter the experience</button>
          </div>
          <div class="text-center">
            <button class="btn btn-white btn-block btn-lg " role="button " (click)="gotoFAQ() " data-automation-id="jumbotronFAQBtn">View the FAQs</button>
          </div>
        </div>
      </div>
      <div *ngIf="!state.GetOpenForBusiness() " class="col-xs-12 col-md-8 col-md-offset-2 ">
        <app-fire-content-block id="wfDoormat "></app-fire-content-block>
      </div>
    </div>
  </div>
</div>
