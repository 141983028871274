<footer class="footer" [ngClass]="{ 'push-top': hasMargin }">
  <div class="container">
    <div class="row">
      <div class="col-sm-3 col-md-3">
        <div class="footer-logo">
          <app-fire-content-block id="wfFooterBrand"></app-fire-content-block>
        </div>
      </div>

      <div class="col-sm-5 col-md-6">
        <div class="footer-about soft-bottom">
          <app-fire-content-block id="wfFooterCopy"></app-fire-content-block>
        </div>
      </div>

      <div class="col-sm-4 col-md-3">
        <div class="footer-social-icons">
          <app-fire-content-block id="wfFooterSocial"></app-fire-content-block>
        </div>
      </div>
    </div>
  </div>
</footer>