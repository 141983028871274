import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, mergeMap, take } from "rxjs/operators";
import {
  AssessmentFragment,
  ConfigFragment,
  GetAssessmentGQL,
  GetWayfinderAnswersGQL,
  GetWayfinderConfigGQL,
  GetWayfinderContentGQL,
  GetWayfinderQuestionsGQL
} from "../../generated/graphql";
import { StateService } from "../services/state.service";
import {
  answers,
  assessment,
  config,
  content,
  preloader,
  questions
} from "../shared/state/state.resolver";

@Injectable({
  providedIn: "root"
})
export class MainResolver implements Resolve<boolean> {
  constructor(
    private router: Router,
    private getAnswers: GetWayfinderAnswersGQL,
    private getAssessment: GetAssessmentGQL,
    private getConfig: GetWayfinderConfigGQL,
    private getQuestions: GetWayfinderQuestionsGQL,
    private getContent: GetWayfinderContentGQL,
    private state: StateService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.getConfig
      .watch()
      .valueChanges.pipe(
        mergeMap(result => {
          const wayfinderConfig = result.data.getWayfinderConfig;
          config(wayfinderConfig);

          return this.getQuestions
            .watch(
              { version: wayfinderConfig.currentversion },
              { fetchPolicy: "no-cache" }
            )
            .valueChanges.pipe(
              map(result => {
                const wayfinderQuestions = result.data.getWayfinderQuestions;
                questions(wayfinderQuestions);
                return wayfinderConfig;
              }),
              take(1)
            );
        }),
        mergeMap(config => {
          return this.getAnswers
            .watch(
              { version: config.currentversion },
              { fetchPolicy: "no-cache" }
            )
            .valueChanges.pipe(
              map(result => {
                const WayfinderAnswer = result.data.getWayfinderAnswers;
                answers(WayfinderAnswer);
                return config;
              }),
              take(1)
            );
        }),
        mergeMap(config => {
          return this.getContent.watch().valueChanges.pipe(
            map(result => {
              const contentBlocks = result.data.getWayfinderContentBlocks || [];
              content(contentBlocks);
              return config;
            }),
            take(1)
          );
        }),
        mergeMap(config => {
          return this.getAssessment.fetch().pipe(
            map(result => {
              return {
                assessment: result.data.assessment,
                config: config
              };
            })
          );
        })
      )
      .pipe(
        map(result => {
          const config = result.config;
          const assessment = result.assessment;
          if (!config) return;

          this.state.SetOpenForBusiness(config.openforbusiness);
          if (
            config.openforbusiness === true ||
            route.url.length === 0 ||
            this.state.GetDebugMode()
          ) {
            this.resumeOldAssessment(route, config, assessment);
            return true;
          } else {
            this.router.navigate(["/"], {
              preserveFragment: true,
              queryParamsHandling: "preserve"
            });
            return false;
          }
        }),
        take(1)
      );
  }

  resumeOldAssessment(
    route: ActivatedRouteSnapshot,
    config: ConfigFragment,
    assessment: AssessmentFragment
  ): boolean {
    route.queryParamMap.get("debug")
      ? this.state.SetDebugMode(true)
      : this.state.SetDebugMode(false);

    if (this.state.GetAppJustLoaded()) {
      this.state.SetAppLoaded();

      if (
        this.state.GetOpenForBusiness() !== true &&
        this.state.GetDebugMode() === false
      ) {
        return true;
      }
      const assessmentId = route.queryParamMap.get("AssessmentID")
        ? route.queryParamMap.get("AssessmentID")
        : assessment?._id;

      if (
        assessmentId &&
        (assessment?.version === config.currentversion ||
          this.state.GetDebugMode() === true)
      ) {
        return this.resumeAssessment();
      } else {
        return this.clearOldAssessment();
      }
    } else {
      preloader(false);
      return true;
    }
  }

  private resumeAssessment(): boolean {
    this.router.navigate(["/resume"]);
    return true;
  }

  private clearOldAssessment(): boolean {
    assessment(null);
    this.router.navigate([""]);
    return false;
  }
}
