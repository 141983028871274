import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PreLoaderService } from "./preloader.service";

@Component({
  selector: "app-preloader",
  templateUrl: "./preloader.component.html",
  styleUrls: ["./preloader.component.scss"]
})
export class PreLoaderComponent implements OnInit {
  show: Observable<boolean>;

  constructor(public preloaderService: PreLoaderService) {}

  ngOnInit(): void {
    this.show = this.preloaderService.showLoader().pipe(
      map(result => {
        return result;
      })
    );
  }
}
