<div class="container push-bottom">
  <div class="row">
    <div class="col-xs-12 col-md-8 col-md-offset-2" data-automation-id="wf-results-overview-html">
      <nav class="push-ends">
        <a routerLink="./seeking" data-automation-id="seeking-link">Seeking</a>
        <a routerLink="./receiving" data-automation-id="receiving-link">Receiving</a>
        <a routerLink="./following" data-automation-id="following-link">Following</a>
        <a routerLink="./producing" data-automation-id="producing-link">Producing</a>
      </nav>
      <router-outlet></router-outlet>
      <a [routerLink]="['/results']" class="btn btn-blue push-ends" data-automation-id="back-to-results">Back to my results</a>
    </div>
  </div>
