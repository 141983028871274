import { of as observableOf, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { DataService } from "./data.service";
import { Injectable } from "@angular/core";
import { encouragementPrefix } from "../shared/constants";
import { ContentFragment } from "../../generated/graphql";

@Injectable()
export class ContentService {
  constructor(private dataService: DataService) {}

  public getContent(contentBlockTitle: string): Observable<ContentFragment> {
    return this.dataService.GetContentBlocks().pipe(
      map(contentBlocks => {
        const contentBlock = contentBlocks.filter(
          content => content.title === contentBlockTitle
        );
        return contentBlock[0];
      })
    );
  }

  public getEncouragements(): Observable<ContentFragment[]> {
    return this.dataService.GetContentBlocks().pipe(
      map(contentBlocks => {
        return contentBlocks
          .filter(content => {
            return content.title
              ? content.title.startsWith(encouragementPrefix)
              : false;
          })
          .sort((a: ContentFragment, b: ContentFragment) => {
            const aSort = parseInt(a.title.replace(/[^\d.]/g, ""), 10);
            const bSort = parseInt(b.title.replace(/[^\d.]/g, ""), 10);
            if (aSort == bSort) {
              return 0;
            }
            if (aSort > bSort) {
              return 1;
            } else {
              return -1;
            }
          });
      })
    );
  }
}
