import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CRDSSiteService } from "../../services/crossroads.site.service";
import { CRDSSite } from "../../models/crds.site";
import { InfoFormData } from "../../models/InfoFormData";
import { AssessmentService } from "../../services/assessment.service";
import { Router } from "@angular/router";
import { AnalyticsService } from "../../services/analytics.service";
import { AuthService } from "../../services/auth.service";
import { emailMatcher } from "../../validators/email-matcher";
import { map, mergeMap } from "rxjs/operators";
import { GroupMembershipFragment } from "../../../generated/graphql";

@Component({
  selector: "app-info-page",
  templateUrl: "./info-page.component.html",
  styleUrls: ["../question-progress-bar/question-progress-bar.component.scss"]
})
export class InfoPageComponent implements OnInit {
  public infoFormGroup: FormGroup;
  public isSubmitted = false;
  public infoData: InfoFormData = new InfoFormData();
  public crossroadsSites: CRDSSite[];
  private emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public disableButton = false;
  public showContact = false;

  constructor(
    private crossroadsSitesService: CRDSSiteService,
    private assessmentService: AssessmentService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.crossroadsSitesService
      .getSites()
      .subscribe(sites => (this.crossroadsSites = sites));
    this.infoFormGroup = this.getForm();
  }

  public groupMembershipClicked(member: boolean) {
    if (!member) {
      this.infoFormGroup.get("isGroupLeader").setValidators(null);
      this.infoFormGroup.get("isGroupLeader").setValue(null);
      this.infoFormGroup.get("canContact").setValidators(Validators.required);
      this.infoFormGroup.controls["canContact"].setErrors({
        incorrect: true
      });
    } else {
      this.infoFormGroup.get("canContact").setValidators(null);
      this.infoFormGroup.get("canContact").setValue(null);
      this.infoFormGroup
        .get("isGroupLeader")
        .setValidators(Validators.required);
      this.infoFormGroup.get("isGroupLeader").setErrors({
        incorrect: true
      });
    }
  }

  public churchSelectionClicked() {
    switch (this.infoData["church"]) {
      case "crossroads":
        this.clearOtherChurchDetail();

        this.infoFormGroup.controls["siteDetail"].setValidators(
          Validators.required
        );
        this.infoFormGroup.controls["isGroupMember"].setValidators(
          Validators.required
        );
        this.infoFormGroup.controls["isGroupMember"].setErrors({
          incorrect: true
        });

        break;
      case "other":
        this.clearSiteDetail();
        this.infoFormGroup.controls["otherChurchDetail"].setValidators(
          Validators.required
        );
        break;
      case "none":
        this.clearSiteDetail();
        this.clearOtherChurchDetail();
        break;
      default:
        break;
    }
    this.infoFormGroup.controls["siteDetail"].updateValueAndValidity();
    this.infoFormGroup.controls["otherChurchDetail"].updateValueAndValidity();
  }

  public onSubmit(value) {
    this.isSubmitted = true;
    if (this.infoFormGroup.valid) {
      this.disableButton = true;

      this.authService
        .anonymousLogin(this.infoData.firstName, this.infoData.lastName)
        .pipe(
          mergeMap(currentUser => {
            return this.assessmentService.GetAssessment().pipe(
              map(assessment => {
                return {
                  currentUser: currentUser,
                  assessment: assessment
                };
              })
            );
          })
        )
        .subscribe(info => {
          const currentUser = info.currentUser;
          let assessment = info.assessment;

          this.analyticsService.trackWayfinderAssessmentStarted(assessment._id);

          switch (this.infoData.church) {
            case "crossroads":
              assessment.congregation = this.infoData.site.name;
              assessment.congregationid = this.infoData.site.id;
              assessment.groupMembership = <GroupMembershipFragment>{
                isGroupMember: this.infoData.isGroupMember,
                isGroupLeader: this.infoData.isGroupLeader,
                canContact: this.infoData.canContact
              };
              break;
            case "other":
              assessment.congregation = this.infoData.otherChurchDetail;
              assessment.congregationid = -1;
              break;
            case "none":
              assessment.congregation = "none";
              assessment.congregationid = -1;
              break;
            default:
              break;
          }
          assessment.firstname = this.infoData.firstName;
          assessment.lastname = this.infoData.lastName;
          assessment.email = this.infoData.email;
          assessment.uid = currentUser._id;
          assessment.reflectionEmailSent = false;
          assessment.resultsEmailSent = false;
          assessment.startDate = `${new Date()}`;
          this.assessmentService
            .UpdateAssessment(assessment)
            .subscribe(newAssessment => {
              console.log(newAssessment._id);
              this.router.navigate(["questions"]);
            });
        });
    } else {
      this.disableButton = false;
      this.infoFormGroup.get("firstName").markAsTouched();
      this.infoFormGroup.get("lastName").markAsTouched();
      this.infoFormGroup
        .get("emailGroup")
        .get("email")
        .markAsTouched();
      this.infoFormGroup
        .get("emailGroup")
        .get("emailConf")
        .markAsTouched();
      this.infoFormGroup.get("siteDetail").markAsTouched();
      this.infoFormGroup.get("otherChurchDetail").markAsTouched();
      this.infoFormGroup.get("isGroupMember").markAsTouched();
      this.infoFormGroup.get("isGroupLeader").markAsTouched();
      this.infoFormGroup.get("canContact").markAsTouched();
    }
  }

  private clearSiteDetail() {
    this.infoFormGroup.get("siteDetail").setValidators(null);
    this.infoFormGroup.get("siteDetail").setValue(null);
    this.infoFormGroup.get("siteDetail").markAsPristine();
    this.infoFormGroup.get("isGroupMember").setValidators(null);
    this.infoFormGroup.get("isGroupMember").setValue(null);
    this.infoFormGroup.get("isGroupMember").markAsPristine();
    this.infoFormGroup.get("isGroupLeader").setValidators(null);
    this.infoFormGroup.get("isGroupLeader").setValue(null);
    this.infoFormGroup.get("isGroupLeader").markAsPristine();
    this.infoFormGroup.get("canContact").setValidators(null);
    this.infoFormGroup.get("canContact").setValue(null);
    this.infoFormGroup.get("canContact").markAsPristine();
  }
  private clearOtherChurchDetail() {
    this.infoFormGroup.get("otherChurchDetail").setValidators(null);
    this.infoFormGroup.get("otherChurchDetail").setValue(null);
    this.infoFormGroup.get("otherChurchDetail").markAsPristine();
  }
  private getForm() {
    return this.fb.group({
      firstName: this.fb.control("", [
        Validators.required,
        Validators.maxLength(100)
      ]),
      lastName: this.fb.control("", [
        Validators.required,
        Validators.maxLength(100)
      ]),
      emailGroup: this.fb.group(
        {
          email: this.fb.control("", [
            Validators.required,
            Validators.pattern(this.emailRegex)
          ]),
          emailConf: this.fb.control("", [
            Validators.required,
            Validators.pattern(this.emailRegex)
          ])
        },
        {
          validator: emailMatcher
        }
      ),
      church: this.fb.control("", [Validators.required]),
      siteDetail: this.fb.control("", []),
      otherChurchDetail: this.fb.control("", [Validators.maxLength(200)]),
      isGroupMember: this.fb.control("", []),
      isGroupLeader: this.fb.control("", []),
      canContact: this.fb.control("", [])
    });
  }
}
