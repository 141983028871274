import { NgModule } from "@angular/core";
import { APOLLO_OPTIONS } from "apollo-angular";
import { ApolloClientOptions } from "@apollo/client/core";
import { HttpLink } from "apollo-angular/http";
import { environment } from "../environments/environment";
import { cache } from "./shared/state/state.resolver";

const uri = environment.CRDS_GQL_ENDPOINT;

function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    link: httpLink.create({ uri }),
    cache: cache,
    name: "crds-wayfinder",
    defaultOptions: {
      watchQuery: {
        errorPolicy: "ignore"
      }
    }
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink]
    }
  ]
})
export class GraphQLModule {}
