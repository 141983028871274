import { Component, OnInit } from "@angular/core";
import { AssessmentService } from "../../services/assessment.service";
import { Router } from "@angular/router";
import { StateService } from "../../services/state.service";
import { AssessmentFragment } from "../../../generated/graphql";
import { assessment } from "../../shared/state/state.resolver";

@Component({
  selector: "app-resume-confirmation",
  templateUrl: "./resume-confirmation.component.html"
})
export class ResumeConfirmationComponent implements OnInit {
  private assessment: AssessmentFragment;
  public ready = false;
  constructor(
    private assessmentService: AssessmentService,
    private router: Router,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.assessmentService.GetAssessment().subscribe(assessment => {
      this.assessment = assessment;
      this.ready = true;
    });
  }

  public resume(): void {
    let url = "";
    const unfinishedQuestions: boolean = !!this.assessment.responses.find(
      r => !r.answertext
    );
    if (unfinishedQuestions) {
      url = "questions";
    } else {
      if (this.stateService.GetDebugMode()) {
        url = "results";
      } else {
        url = !!this.assessment.completedDate
          ? this.stateService.GetFinalPage()
          : this.getOpenResponseURL();
      }
    }
    this.router.navigate([url]);
  }

  public clearOldAssessment(): void {
    assessment(null);
    this.router.navigate([""]);
  }

  private getOpenResponseURL(): string {
    if (!this.assessment.openResponse1) {
      return "openresponse/intro";
    }

    if (!this.assessment.openResponse2) {
      return "openresponse/2";
    }

    if (!this.assessment.openResponse3) {
      return "openresponse/3";
    }

    if (!this.assessment.openResponse4) {
      return "openresponse/4";
    }

    return "openresponse/ideas";
  }
}
