<div class="audio-wrapper" [ngClass]="{'audio-loaded': audioLoaded, 'audio-not-loaded' : !audioLoaded}" data-automation-id="wf-audio-player">
  <div class="audio-container">
    <div class="circle">
      <div class="circle-1"></div>
      <div class="circle-2"></div>
    </div>
    <div class="btn" [ngClass]="{'pause': audioPlaying, 'play' : !audioPlaying}" (click)="changeAudioState()">
      <span class="bar bar-1"></span>
      <span class="bar bar-2"></span>
      <div class="preloader-wrapper">
        <svg viewBox="0 0 102 101" class="preloader">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <ellipse stroke="#eee" cx="50" cy="49.421" rx="50" ry="49.421"></ellipse>
              <path d="M50 98.842c27.614 0 50-22.127 50-49.42C100 22.125 77.614 0 50 0" stroke-opacity=".631" stroke="#3B6E8F"></path>
            </g>
          </g>
        </svg>
      </div>
      <div class="text">music</div>
    </div>
  </div>
</div>
