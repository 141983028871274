import { Component, OnInit, Input } from "@angular/core";
import { Score } from "../../models/score";
import { categoryBoundries } from "../../shared/constants";

@Component({
  selector: "app-results-chart",
  templateUrl: "./results-chart.component.html"
})
export class ResultsChartComponent implements OnInit {
  @Input() score: Score;

  bars = {
    financial: 0.0,
    intellectual: 0.0,
    physical: 0.0,
    relational: 0.0,
    spiritual: 0.0
  };

  ngOnInit() {
    this.calcBarValues();
  }

  public barPath(name, top, sectionWidth) {
    const val = this.bars[name];
    const right = val * sectionWidth + 82 - 4;
    return [
      `M82,${top}`,
      `L${right},${top}`,
      `C${right + 2},${top}`,
      `${right + 4},${top + 2}`,
      `${right + 4},${top + 4}`,
      `L${right + 4},${top + 45}`,
      `C${right + 4},${top + 47}`,
      `${right + 2},${top + 49}`,
      `${right},${top + 49}`,
      `L82,${top + 49}`,
      `L82,0`,
      `Z`
    ].join(" ");
  }

  private calcBarValues(): void {
    this.bars.financial = this.doCalc(this.score.financial);
    this.bars.intellectual = this.doCalc(this.score.intellectual);
    this.bars.physical = this.doCalc(this.score.physical);
    this.bars.relational = this.doCalc(this.score.relational);
    this.bars.spiritual = this.doCalc(this.score.spiritual);
  }

  private doCalc(categoryScore: number): number {
    if (categoryScore < categoryBoundries.SEEKING_LOW) {
      return categoryBoundries.SEEKING_LOW;
    }

    if (categoryScore <= categoryBoundries.SEEKING_HIGH) {
      return (
        (categoryScore - categoryBoundries.SEEKING_LOW) /
        (categoryBoundries.SEEKING_HIGH - categoryBoundries.SEEKING_LOW)
      );
    }

    if (categoryScore <= categoryBoundries.RECEIVING_HIGH) {
      return (
        1 +
        (categoryScore - categoryBoundries.RECEIVING_LOW) /
          (categoryBoundries.RECEIVING_HIGH - categoryBoundries.RECEIVING_LOW)
      );
    }

    if (categoryScore <= categoryBoundries.FOLLOWING_HIGH) {
      return (
        2 +
        (categoryScore - categoryBoundries.FOLLOWING_LOW) /
          (categoryBoundries.FOLLOWING_HIGH - categoryBoundries.FOLLOWING_LOW)
      );
    }

    return (
      3 +
      (categoryScore - categoryBoundries.PRODUCING_LOW) /
        (categoryBoundries.PRODUCING_HIGH - categoryBoundries.PRODUCING_LOW)
    );
  }
}
