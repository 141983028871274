<div class="container">
  <div class="row">
    <div class="col-md-6 col-md-offset-3 soft-ends mobile-flush-top push-half-top">
      <div class="progress">
        <div class="progress-bar progress-bar-teal" style="width: 100%;"></div>
      </div>
    </div>

    <div class="col-md-6 col-md-offset-3">
      <div class="soft-bottom">
        <app-fire-content-block id="wfOpenGroup"></app-fire-content-block>
      </div>

      <form [formGroup]="learnedForm" (ngSubmit)="submitForm(learnedForm)">
        <div class="soft-bottom">
          <button type="submit" class="btn btn-blue btn-block btn-large" data-automation-id="wf-final-submit">Finish</button>
        </div>
      </form>

    </div>
  </div>
</div>