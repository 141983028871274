<div class="container">
  <div class="row">
    <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 soft-ends mobile-flush-top push-half-top">
      <div class="progress">
        <div class="progress-bar progress-bar-teal" style="width: 0%;"></div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <app-fire-content-block id="wfInfoPageIntro"></app-fire-content-block>
      <form [formGroup]="infoFormGroup" (ngSubmit)="onSubmit(infoFormGroup)" class="soft-top">
        <div class="row soft-bottom">
          <div class="col-xs-12">
            <label class="control-label block" data-automation-id="wf-info-personal-label">Personal Info</label>
          </div>
          <div class="col-xs-6">
            <div class="form-group">
              <label class="sr-only" for="firstName" data-automation-id="wf-info-firstName-label">First name</label>
              <input class="form-control" data-automation-id="wf-info-firstName" [(ngModel)]="infoData.firstName" formControlName="firstName" name="firstName" placeholder="First Name" maxlength="100" />
              <div class="error help-block" role="alert" *ngIf="isSubmitted && !infoFormGroup.controls['firstName'].valid">
                <app-fire-content-block id="wfInfoFirstNameError"></app-fire-content-block>
              </div>
            </div>
          </div>

          <div class="col-xs-6">
            <div class="form-group">
              <label class="sr-only" for="lastName" data-automation-id="wf-info-lastName-label">Last name</label>
              <input class="form-control" data-automation-id="wf-info-lastName" [(ngModel)]="infoData.lastName" formControlName="lastName" name="lastName" placeholder="Last Name" maxlength="100" />
              <div class="error help-block" role="alert" *ngIf="isSubmitted && !infoFormGroup.controls['lastName'].valid">
                <app-fire-content-block id="wfInfoLastNameError"></app-fire-content-block>
              </div>
            </div>
          </div>
          <div formGroupName="emailGroup">
            <div class="col-xs-12">
              <div class="form-group">
                <label class="sr-only" for="email" data-automation-id="wf-info-email-label">Email address</label>
                <input class="form-control" data-automation-id="wf-info-email" [(ngModel)]="infoData.email" formControlName="email" name="email" type="email" placeholder="Email" />
              </div>
            </div>

            <div class="col-xs-12">
              <div class="form-group">
                <label class="sr-only" for="emailConf" data-automation-id="wf-info-email-conf-label">Confirm Email address</label>
                <input class="form-control" data-automation-id="wf-info-email-conf" formControlName="emailConf" name="emailConf" type="email" placeholder="ConfirmEmail" onDrop="return false" onPaste="return false" />
                <div class="error help-block" role="alert" *ngIf="isSubmitted && !infoFormGroup.get('emailGroup').get('email').valid">
                  <app-fire-content-block id="wfInfoEmailError"></app-fire-content-block>
                </div>
                <div
                  class="error help-block"
                  role="alert"
                  *ngIf="
                    isSubmitted &&
                    infoFormGroup
                      .get('emailGroup')
                      .get('emailConf')
                      .hasError('nomatch')
                  "
                >
                  <app-fire-content-block id="wfInfoEmailMismatch"></app-fire-content-block>
                </div>
                <p class="help-block">
                  <app-fire-content-block id="wfInfoSameEmail"></app-fire-content-block>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row soft-bottom">
          <div class="col-xs-12">
            <div class="form-group">
              <label class="control-label block" data-automation-id="wf-info-crossroads-label">Which church do you attend?</label>
              <div class="btn-group btn-group-block" role="group" aria-label="...">
                <button type="button" data-automation-id="wf-info-crossroads" class="btn btn-default" [(ngModel)]="infoData.church" (click)="churchSelectionClicked()" formControlName="church" btnRadio="crossroads">
                  Crossroads
                </button>

                <div class="row" *ngIf="infoData.church === 'crossroads'">
                  <div class="col-xs-12 clear">
                    <div class="border-left border-width-3">
                      <div class="push-half-left">
                        <div class="form-group push-quarter-ends">
                          <label for="siteDetail" class="sr-only" data-automation-id="wf-info-site-select-label">Crossroads</label>
                          <select class="form-control" data-automation-id="wf-info-site-select" name="siteDetail" [(ngModel)]="infoData.site" formControlName="siteDetail">
                            <option value="null" disabled="true" [selected]="!infoData.church.selected">Which site?</option>
                            <option *ngFor="let site of crossroadsSites" [ngValue]="site">{{ site.name }}</option>
                          </select>

                          <p class="error help-block" role="alert" *ngIf="isSubmitted && !infoFormGroup.controls['siteDetail'].valid">
                            <app-fire-content-block id="wfInfoSiteError"></app-fire-content-block>
                          </p>

                          <div *ngIf="infoFormGroup.controls['siteDetail'].valid">
                            <br />

                            <div class="form-group" data-automation-id="is-group-member">
                              <label class="control-label block">
                                <app-fire-content-block id="wfInfoGroupMember"></app-fire-content-block>
                              </label>
                              <div class="btn-group btn-group-bar" role="group" aria-label="...">
                                <button type="button" class="btn btn-default" [(ngModel)]="infoData.isGroupMember" [btnRadio]="true" uncheckable formControlName="isGroupMember" (click)="groupMembershipClicked(true)">
                                  Yes
                                </button>
                                <button type="button" class="btn btn-default" [(ngModel)]="infoData.isGroupMember" [btnRadio]="false" uncheckable formControlName="isGroupMember" (click)="groupMembershipClicked(false)">
                                  No
                                </button>
                              </div>
                              <p class="error help-block" role="alert" *ngIf="isSubmitted && !infoFormGroup.controls['isGroupMember'].valid">
                                <app-fire-content-block id="wfInfoChurchError"></app-fire-content-block>
                              </p>
                            </div>

                            <div class="form-group" data-automation-id="can-contact" *ngIf="infoData.isGroupMember === true">
                              <label class="control-label block">
                                <app-fire-content-block id="wfInfoGroupLeader"></app-fire-content-block>
                              </label>
                              <div class="btn-group btn-group-bar" role="group" aria-label="...">
                                <button type="button" class="btn btn-default" [(ngModel)]="infoData.isGroupLeader" [btnRadio]="true" uncheckable formControlName="isGroupLeader">Yes</button>
                                <button type="button" class="btn btn-default" [(ngModel)]="infoData.isGroupLeader" [btnRadio]="false" uncheckable formControlName="isGroupLeader">No</button>
                              </div>
                              <p class="error help-block" role="alert" *ngIf="isSubmitted && !infoFormGroup.controls['isGroupLeader'].valid">
                                <app-fire-content-block id="wfInfoChurchError"></app-fire-content-block>
                              </p>
                            </div>

                            <div class="form-group" data-automation-id="can-contact" *ngIf="infoData.isGroupMember === false">
                              <label class="control-label block">
                                <app-fire-content-block id="wfInfoGroupMemberContact"></app-fire-content-block>
                              </label>
                              <div class="btn-group btn-group-bar" role="group" aria-label="...">
                                <button type="button" class="btn btn-default" [(ngModel)]="infoData.canContact" [btnRadio]="true" uncheckable formControlName="canContact">Yes</button>
                                <button type="button" class="btn btn-default" [(ngModel)]="infoData.canContact" [btnRadio]="false" uncheckable formControlName="canContact">No</button>
                              </div>
                              <p class="error help-block" role="alert" *ngIf="isSubmitted && !infoFormGroup.controls['canContact'].valid">
                                <app-fire-content-block id="wfInfoChurchError"></app-fire-content-block>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button type="button" data-automation-id="wf-info-other-church" class="btn btn-default" (click)="churchSelectionClicked()" [(ngModel)]="infoData.church" formControlName="church" btnRadio="other">
                  A different church
                </button>

                <div class="row" *ngIf="infoData.church === 'other'">
                  <div class="col-xs-12 clear">
                    <div class="border-left border-width-3">
                      <div class="push-half-left">
                        <div class="form-group push-quarter-ends">
                          <label for="otherChurchDetail" class="sr-only" data-automation-id="wf-info-other-church-label">Different Church</label>
                          <input class="form-control" data-automation-id="wf-info-other-church-detail" [(ngModel)]="infoData.otherChurchDetail" formControlName="otherChurchDetail" name="otherChurchDetail" placeholder="Which church?" maxlength="200" />
                          <p class="error help-block" role="alert" *ngIf="isSubmitted && !infoFormGroup.controls['otherChurchDetail'].valid">
                            <app-fire-content-block id="wfInfoOtherChurchError"></app-fire-content-block>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button type="button" data-automation-id="wf-info-dont-attend-church" class="btn btn-default" (click)="churchSelectionClicked()" [(ngModel)]="infoData.church" formControlName="church" btnRadio="none">
                  I don't attend church
                </button>
              </div>

              <div class="error help-block" data-automation-id="wf-info-church-error" role="alert" *ngIf="isSubmitted && !infoFormGroup.controls['church'].valid">
                <app-fire-content-block id="wfInfoChurchError"></app-fire-content-block>
              </div>
            </div>
          </div>
        </div>

        <p class="error help-block" role="alert" *ngIf="isSubmitted && !infoFormGroup.valid">
          <app-fire-content-block id="wfInfoFormSubmitError"></app-fire-content-block>
        </p>

        <div class="row soft-bottom">
          <div class="col-xs-12">
            <button type="submit" [disabled]="disableButton" class="btn btn-blue btn-block btn-lg" data-automation-id="wf-info-begin">Begin</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
