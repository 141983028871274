<div id="thechart" class="chart-container">

  <!-- Desktop Chart -->
  <svg class="sgwayfinder-chart hide-mobile" viewBox="0 0 750 313" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    data-html2canvas-ignore="true">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <!-- Grid Lines -->
      <g transform="translate(81, 0)" stroke="#828282" stroke-linecap="square">
        <path d="M0.5,0.5 L0.5,276.6"></path>
        <path d="M155.0,0.5 L155.0,276.6"></path>
        <path d="M309.5,0.5 L309.5,276.6"></path>
        <path d="M464.0,0.5 L464.0,276.6"></path>
        <path d="M618.5,0.5 L618.5,276.6"></path>
      </g>
      <!-- Y-Axis Labels -->
      <g font-size="14" fill="#737373" font-weight="300">
        <text x="16.552" y="257">Spiritual</text>
        <text x="6.486" y="200">Relational</text>
        <text x="16.146" y="143">Physical</text>
        <text x="0.508" y="86">Biblical Truth</text>
        <text x="11.82" y="29">Financial</text>
      </g>
      <!-- Bars -->
      <g fill="#57AFA9">
        <path [attr.d]="barPath('spiritual', 228, 154.5)"></path>
        <path [attr.d]="barPath('relational', 171, 154.5)"></path>
        <path [attr.d]="barPath('physical', 114, 154.5)"></path>
        <path [attr.d]="barPath('intellectual', 57, 154.5)"></path>
        <path [attr.d]="barPath('financial', 0, 154.5)"></path>
      </g>
      <!-- X-Axis Labels -->
      <g transform="translate(59, 299)" fill="#737373" font-size="14" font-weight="300">
        <text x="75" y="10">Seeking</text>
        <text x="225" y="10">Receiving</text>
        <text x="375" y="10">Following</text>
        <text x="525" y="10">Producing</text>
      </g>
    </g>
  </svg>

  <!-- Mobile Chart -->
  <svg class="sgwayfinder-chart hide-desktop" viewBox="0 0 325 352" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    data-html2canvas-ignore="true">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <!-- Grid Lines -->
      <g transform="translate(81, 0)" stroke="#E7E7E7" stroke-linecap="square">
        <path d="M0.5,0.5 L0.5,276.6"></path>
        <path d="M54.25,0.5 L54.25,276.6"></path>
        <path d="M108.0,0.5 L108.0,276.6"></path>
        <path d="M161.75,0.5 L161.75,276.6"></path>
        <path d="M215.5,0.5 L215.5,276.6"></path>
      </g>
      <!-- Y-Axis Labels -->
      <g font-size="14" font-weight="300" fill="#737373">
        <text x="16.552" y="257">Spiritual</text>
        <text x="6.486" y="200">Relational</text>
        <text x="16.146" y="143">Physical</text>
        <text x="0.508" y="86">Intellectual</text>
        <text x="11.82" y="29">Financial</text>
      </g>
      <!-- Bars -->
      <g fill="#57AFA9">
        <path [attr.d]="barPath('spiritual', 228, 53.75)"></path>
        <path [attr.d]="barPath('relational', 171, 53.75)"></path>
        <path [attr.d]="barPath('physical', 114, 53.75)"></path>
        <path [attr.d]="barPath('intellectual', 57, 53.75)"></path>
        <path [attr.d]="barPath('financial', 0, 53.75)"></path>
      </g>
      <!-- X-Axis Labels -->
      <g transform="translate(76, 288)" fill="#737373" font-size="14" font-weight="300">
        <text transform="translate(30, 25) rotate(-90) translate(-7.5, -25)" x="-16.174" y="28">
          Seeking
        </text>
        <text transform="translate(83.75, 30) rotate(-90) translate(-78, -30)" x="48.938" y="33">
          Receiving
        </text>
        <text transform="translate(137.5, 30) rotate(-90) translate(-149, -30)" x="120.12" y="33">
          Following
        </text>
        <text transform="translate(191.25, 32) rotate(-90) translate(-221, -32)" x="190.48" y="35">
          Producing
        </text>
      </g>
    </g>
  </svg>

</div>
