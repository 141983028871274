<div class="container">
  <div class="row">
    <div class="col-md-6 col-md-offset-3 soft-ends mobile-flush-top push-half-top">
      <div class="progress">
        <div class="progress-bar progress-bar-teal" style="width: 100%;"></div>
      </div>
    </div>

    <div class="col-md-6 col-md-offset-3">
      <div class="soft-bottom">
        <app-fire-content-block id="{{getContentId()}}"></app-fire-content-block>
      </div>

      <form [formGroup]="openResponseForm" (ngSubmit)="doClick()">
        <div class="soft-bottom" *ngIf="currentPage.saveTo != null">
          <div class="form-group">

            <textarea class="form-control" rows="3" name="openresponse" formControlName="openresponse" data-automation-id="wf-final-textopenresponse"
              maxlength="500"></textarea>
            <div class="error help-block" role="alert" *ngIf="isSubmitted && !openResponseForm.controls['openresponse'].valid">
              <app-fire-content-block id="wfFinalLearnedError"></app-fire-content-block>
            </div>
          </div>
        </div>

        <div class="soft-bottom">
          <button type="submit" class="btn btn-blue btn-block btn-large" data-automation-id="wf-final-submit">Next</button>
        </div>
      </form>

    </div>
  </div>
</div>