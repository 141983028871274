<div class="header" style="background-image: url('https://s3.amazonaws.com/crds-cms-uploads/img/graphics/mountain-bkgrd-wayfinder.jpg');">
  <div class="container-fluid">
    <div class="row">
      <div class="header-logo soft-quarter-top">
        <app-fire-content-block id="wfHeaderBrand"></app-fire-content-block>

        <app-audio-player></app-audio-player>
      </div>
    </div>
  </div>
</div>