import { of as observableOf, Observable } from "rxjs";

import { tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { AnswerFragment } from "../../generated/graphql";
@Injectable()
export class AnswersService {
  private answers: AnswerFragment[];
  private currentVersion: string = "1.0.0";

  constructor(private dataService: DataService) {}

  public GetAnswers(): Observable<AnswerFragment[]> {
    return this.dataService.GetAnswers().pipe(
      tap(answers => {
        this.answers = answers;
      })
    );
  }
}
