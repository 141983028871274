import { of, Observable } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Score } from "../models/score";
import { DataService } from "./data.service";
import { Router } from "@angular/router";
import { questionCategory } from "../shared/constants";
import * as _ from "lodash";
import {
  AssessmentEmailStatusFragment,
  AssessmentFragment,
  ConfigFragment,
  QuestionFragment,
  ResponseFragment,
  UserFragment,
  WayfinderAssessmentEmailStatus
} from "../../generated/graphql";
import { assessment } from "../shared/state/state.resolver";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "@apollo/client/utilities";

@Injectable()
export class AssessmentService {
  private score: Score;

  constructor(private dataService: DataService, private router: Router) {}

  public GetAssessment(): Observable<AssessmentFragment> {
    return this.dataService.GetAssessment().pipe(
      mergeMap(assessment => {
        if (assessment?._id) return of(assessment);
        return this.CreateAssessment();
      })
    );
  }

  public UpdateAssessmentProperty(
    propertyName: string,
    value: any
  ): Observable<AssessmentFragment> {
    return this.dataService.GetAssessment().pipe(
      map(tmpAssessment => {
        tmpAssessment = cloneDeep(tmpAssessment);
        tmpAssessment[propertyName] = value;
        assessment(tmpAssessment);
        return tmpAssessment;
      }),
      mergeMap(assessment => {
        return this.dataService.UpdateAssessment(assessment);
      })
    );
  }

  public UpdateAssessment(
    tmpAssessment: AssessmentFragment
  ): Observable<AssessmentFragment> {
    return this.dataService.UpdateAssessment(tmpAssessment).pipe(
      tap(resultAssessment => {
        assessment(resultAssessment);
        return resultAssessment;
      })
    );
  }

  public GetRequiredResponses(): Observable<ResponseFragment[]> {
    return this.dataService.GetAssessment().pipe(
      map(assessment => {
        if (assessment != null) {
          const unanswered = [];
          assessment.responses.forEach(response => {
            if (
              response.answertext == null ||
              response.answertext === undefined
            ) {
              unanswered.push(response);
            }
          });
          console.log(`${assessment.responses.length} Total Questions`);
          console.log(`${unanswered.length} Questions Unanswered`);
          return unanswered;
        } else {
          // something went wrong send back to start page
          this.router.navigate([""]);
          return null;
        }
      })
    );
  }

  public GetTotalQuestions(): Observable<number> {
    return this.dataService.GetAssessment().pipe(
      map(assessment => {
        return assessment.responses.length;
      })
    );
  }

  public SendAssessmentEmail(
    id: string,
    resend: boolean
  ): Observable<AssessmentEmailStatusFragment> {
    return this.dataService.SendAssessmentEmail(id, resend);
  }

  public SaveResponse(
    newResponse: ResponseFragment
  ): Observable<AssessmentFragment> {
    return this.dataService.GetAssessment().pipe(
      mergeMap(assessment => {
        const responses = assessment.responses.map(response => {
          if (response._id === newResponse._id) return newResponse;
          return response;
        });

        return this.UpdateAssessment(<AssessmentFragment>{
          ...assessment,
          responses: responses
        });
      })
    );
  }

  public GetAssessmentId(): Observable<string> {
    return this.dataService.GetAssessment().pipe(
      map(assessment => {
        return assessment._id;
      })
    );
  }

  public GetScore(): Observable<Score> {
    return this.dataService.GetAssessment().pipe(
      map(assessment => {
        if (this.score !== undefined) {
          return this.score;
        }
        const score = new Score();
        score.spiritual = this.calcScore(
          assessment.responses.filter(
            x => x.category === questionCategory.SPIRITUAL
          )
        );
        score.financial = this.calcScore(
          assessment.responses.filter(
            x => x.category === questionCategory.FINANCIAL
          )
        );
        score.intellectual = this.calcScore(
          assessment.responses.filter(
            x => x.category === questionCategory.INTELLECTUAL
          )
        );
        score.relational = this.calcScore(
          assessment.responses.filter(
            x => x.category === questionCategory.RELATIONAL
          )
        );
        score.physical = this.calcScore(
          assessment.responses.filter(
            x => x.category === questionCategory.PHYSICAL
          )
        );

        return score;
      })
    );
  }

  private calcScore(responses: ResponseFragment[]): number {
    let score = 0;
    for (const response of responses) {
      score +=
        response.answervalue *
        response.questionweight *
        response.categoryweight;
    }
    return score;
  }

  private CreateAssessment(): Observable<AssessmentFragment> {
    // get the questions and add them to the assesment
    return this.dataService.GetConfig().pipe(
      mergeMap(config => {
        return this.dataService.GetQuestions().pipe(
          mergeMap(questions => {
            return this.dataService.GetCurrentUser().pipe(
              map(user => {
                return {
                  config: config,
                  questions: questions,
                  user: user
                };
              })
            );
          })
        );
      }),
      mergeMap(info => {
        return this.QuestionsArrayAssessment(
          info.config,
          info.user,
          info.questions
        );
      })
    );
  }

  private QuestionsArrayAssessment(
    config: ConfigFragment,
    user: UserFragment,
    questions: QuestionFragment[]
  ): Observable<AssessmentFragment> {
    return this.dataService.CreateAssessment(config, user).pipe(
      map(assessment => {
        assessment.responses = new Array<ResponseFragment>();
        console.log(`${questions.length} questions before ordering`);
        questions = this.orderQuestions(questions);

        questions.forEach((question, i) => {
          if (question.questiontext == null) {
            console.log(
              `question ${question.questionid} doesn't have questiontext`
            );
            console.log(JSON.stringify(question));
          }
          const response = <ResponseFragment>{
            _id: uuidv4(),
            category: question.category,
            categoryweight: question.categoryweight,
            questionweight: question.questionweight,
            question: question,
            sortorder: i
          };
          assessment.responses.push(response);
        });

        return assessment;
      })
    );
  }

  private orderQuestions(questions: QuestionFragment[]): QuestionFragment[] {
    let sortedQuestions: QuestionFragment[] = [];
    const groupings = _.sortBy(
      _.uniqBy(questions, "questiongrouporder").map(x => {
        return x.questiongrouporder;
      })
    );

    groupings.forEach(grouping => {
      sortedQuestions = sortedQuestions.concat(
        _.shuffle(questions.filter(q => q.questiongrouporder == grouping))
      );
    });

    return sortedQuestions;
  }
}
