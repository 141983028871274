import { Component, OnInit, ViewChild } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { cloneDeep } from "@apollo/client/utilities";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import {
  AnswerFragment,
  AssessmentFragment,
  ResponseFragment
} from "../../../generated/graphql";
import { AnalyticsService } from "../../services/analytics.service";
import { AnswersService } from "../../services/answer.service";
import { AssessmentService } from "../../services/assessment.service";
import { StateService } from "../../services/state.service";
import { QuestionComponent } from "../question/question.component";

@Component({
  selector: "app-question-form",
  templateUrl: "./question-form.component.html"
})
export class QuestionFormComponent implements OnInit {
  public answers: AnswerFragment[];
  public response: ResponseFragment;
  public totalQuestions: number;
  public ready = false;
  public responses: ResponseFragment[];

  @ViewChild(QuestionComponent) question: QuestionComponent;

  constructor(
    private assessmentService: AssessmentService,
    private answerService: AnswersService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.assessmentService
      .GetRequiredResponses()
      .pipe(
        mergeMap(responses => {
          return this.answerService.GetAnswers().pipe(
            mergeMap(answers => {
              return this.assessmentService.GetTotalQuestions().pipe(
                map(totalQuestions => {
                  return {
                    totalQuestions: totalQuestions,
                    answers: answers,
                    responses: responses
                  };
                })
              );
            })
          );
        })
      )
      .subscribe(info => {
        this.responses = info.responses.map(r => cloneDeep(r)).reverse();
        this.totalQuestions = info.totalQuestions;
        this.answers = info.answers
          .map(a => cloneDeep(a))
          .sort((a, b) => {
            return a.sequence - b.sequence;
          });
        this.prepNextQuestion();
        this.ready = true;
      });
  }

  public questionAnswered(response: ResponseFragment) {
    this.analyticsService.trackWayfinderQuestionStatus(
      false,
      response.questionid,
      response.category
    );
    response.enddate = `${new Date()}`;
    return this.assessmentService.SaveResponse(response).subscribe(_ => {
      this.question.resetForm();
    });
  }

  public questionReady() {
    if (this.responses.length >= 1) {
      this.prepNextQuestion();
    } else {
      this.router.navigate(["openresponse/intro"]);
    }
  }

  private prepNextQuestion(): void {
    console.log(
      `questions answered -->${this.totalQuestions - this.responses.length}`
    );

    if (
      this.totalQuestions - this.responses.length === 33 &&
      !this.stateService.GetBreath1Taken()
    ) {
      const navigationExtras: NavigationExtras = {
        queryParams: { breathe: "inhale" }
      };
      this.router.navigate(["stopandbreathe"], navigationExtras);
    }

    if (
      this.totalQuestions - this.responses.length === 66 &&
      !this.stateService.GetBreath2Taken()
    ) {
      const navigationExtras: NavigationExtras = {
        queryParams: { breathe: "exhale" }
      };
      this.router.navigate(["stopandbreathe"], navigationExtras);
    }

    if (this.responses.length > 0) {
      this.response = this.responses.pop();
      this.response.startdate = `${new Date()}`;
      this.analyticsService.trackWayfinderQuestionStatus(
        true,
        this.response.questionid,
        this.response.category
      );
    }
  }
}
