import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";
import {
  CreateWayfinderUserGQL,
  GetCurrentUserGQL,
  UserFragment,
  WayfinderUserInput
} from "../../generated/graphql";
import { currentUser } from "../shared/state/state.resolver";

interface IUser {
  uid: string;
  email?: string | null;
  firstName?: string;
  lastName?: string;
}

@Injectable()
export class AuthService {
  private uid: string;

  constructor(
    private getCurrentUser: GetCurrentUserGQL,
    private createUser: CreateWayfinderUserGQL
  ) {}

  anonymousLogin(
    firstname: string,
    lastname: string
  ): Observable<UserFragment> {
    return this.getCurrentUser.fetch().pipe(
      mergeMap(result => {
        const user = <UserFragment>result.data.currentUser;
        if (user._id) return of(user);
        return this.createNewUser(firstname, lastname);
      })
    );
  }

  private createNewUser(
    firstname: string,
    lastname: string
  ): Observable<UserFragment> {
    return this.createUser
      .mutate({
        user: <WayfinderUserInput>{
          lastname: lastname,
          firstname: firstname
        }
      })
      .pipe(
        map(res => {
          const user = res.data.createWayfinderUser;

          currentUser(user);
          return user;
        })
      );
  }
}
