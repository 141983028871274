import { map, mergeMap } from "rxjs/operators";
import { StateService } from "../services/state.service";
import { Injectable } from "@angular/core";
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { QuestionFormComponent } from "../components/question-form/question-form.component";
import { Observable } from "rxjs";
import { PlatformLocation } from "@angular/common";
import { DataService } from "../services/data.service";
import { ContentService } from "../services/content.service";
import { AssessmentService } from "../services/assessment.service";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class QuestionGuard implements CanDeactivate<QuestionFormComponent> {
  constructor(
    private toast: ToastrService,
    private assessmentService: AssessmentService,
    private dataService: DataService,
    private location: PlatformLocation,
    private stateService: StateService,
    private contentService: ContentService
  ) {}

  canDeactivate(
    _component: QuestionFormComponent,
    currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> {
    return this.dataService.GetAssessment().pipe(
      mergeMap(assessment => {
        return this.assessmentService.GetRequiredResponses().pipe(
          map(requiredResponses => {
            return {
              assessment: assessment,
              requiredResponses: requiredResponses
            };
          })
        );
      }),
      map(info => {
        switch (currentRoute.url[0].toString()) {
          case "questions":
            if (
              nextState.url.startsWith("/stopandbreathe?breathe=inhale") &&
              this.stateService.GetBreath1Taken() === false
            ) {
              return true;
            } else if (
              nextState.url.startsWith("/stopandbreathe?breathe=exhale") &&
              this.stateService.GetBreath2Taken() === false
            ) {
              return true;
            } else {
              const responses = info.requiredResponses;
              const questionsFinished = responses.length === 0;
              if (!questionsFinished) {
                this.showToastError();
                this.location.pushState(null, null, "/questions");
              }
              return questionsFinished;
            }
          case "wrapup":
            const finished = info.assessment.completedDate != null;
            if (!finished) {
              this.showToastError();
              this.location.pushState(null, null, "/wrapup");
            }
            return finished;
          default:
            this.showToastError();
            return false;
        }
      })
    );
  }

  private showToastError() {
    this.contentService.getContent("wfBackError").subscribe(block => {
      const text = block
        ? block.content
        : "Sorry, you may not go back once you've started";
      this.toast.error(text, "", {
        timeOut: 3000
      });
    });
  }
}
