import { Component, OnChanges, Input } from "@angular/core";
import { EmbeddedTooltip } from "../../models/embeddedTooltip";
import { AnalyticsService } from "../../services/analytics.service";

@Component({
  selector: "app-question-markup-composer",
  template:
    '<span *ngFor="let tooltip of tooltips">' +
    "{{tooltip.textBefore}}" +
    '<span class="ng-inserted-tooltip" *ngIf="tooltip.keywords" (click)="tooltipAction(tooltip.keywords, \'click\')" (mouseover)="tooltipAction(tooltip.keywords, \'hover\')" [tooltip]="tooltip.tooltip">' +
    "{{tooltip.keywords}}" +
    "</span>" +
    "</span>"
})
export class QuestionMarkupComposerComponent implements OnChanges {
  @Input() questionText: string;
  @Input() questionId: string;

  public tooltips: EmbeddedTooltip[];

  constructor(private analyticsService: AnalyticsService) {}

  ngOnChanges() {
    this.tooltips = this.getTooltipsFromQuestion(this.questionText);
  }

  public tooltipAction(keywords, action) {
    this.analyticsService.trackWayfinderTooltipClicked(
      this.questionId,
      keywords,
      action
    );
  }

  private getTooltipsFromQuestion(question: string): EmbeddedTooltip[] {
    let embeddedTooltips: EmbeddedTooltip[] = [];

    //split the question text into chuncks that end after each tooltip.
    //format is:
    //`text before ((keyword tooltip))[[tooltip text]] some more text((another keyword))[[another tooltip]]`
    //would split into: [
    //    'text before ((keyword tooltip))[[tooltip text]]',
    //    ' some more text((another keyword))[[another tooltip]]'
    //]
    question?.split("]]")?.forEach(piece => {
      if (piece) {
        //look for [[ and everything after it
        let tooltipMatchResults = /\[{2}(.*)/.exec(piece);
        //look for (( and tak eveything until ))
        let keywordsMatchResults = /\({2}(.*)\){2}/.exec(piece);
        //start at the beginning of the string and take everything until ((
        let textBeforeMatchResults = /^.*(?=(\({2}))/.exec(piece);

        embeddedTooltips.push(
          new EmbeddedTooltip(
            //this returns the match minus the (( or the entire string if it doesn't exits (because no tooltips
            //are present but we need still need the text)
            textBeforeMatchResults ? textBeforeMatchResults[0] : piece,
            //this returns the match minus (( and )) or null if it didn't exist
            keywordsMatchResults ? keywordsMatchResults[1] : null,
            //this returns the match minu ]] or null if it didn't exist
            tooltipMatchResults ? tooltipMatchResults[1] : null
          )
        );
      }
    });
    return embeddedTooltips;
  }
}
