<div class="container push-bottom results">
  <div class="row">
    <div class="col-xs-12 col-md-8 col-md-offset-2" data-automation-id="wf-results-html">

      <h1 class="page-header">your results</h1>

      <app-fire-content-block id="wfResultsIntroduction"></app-fire-content-block>

      <blockquote class="font-family-base">
        <br>
        <app-fire-content-block id="wfFinalPageQuestion"></app-fire-content-block>

        <br>
        <span data-automation-id="learned-response">{{assessment.learned}}</span>
      </blockquote>

      <app-results-chart [score]="score" data-automation-id="wf-results-chart"></app-results-chart>

      <h3 class="font-family-condensed-extra font-size-h4 text-uppercase" data-automation-id="spiritual-classification">Spiritual Identity: {{ getClassification(score.spiritual) }}</h3>
      <app-fire-content-block id="wfSpiritualDesc"></app-fire-content-block>
      <app-fire-content-block id="{{getContentBlock('Spiritual', score.spiritual)}}"></app-fire-content-block>

      <h3 class="font-family-condensed-extra font-size-h4 text-uppercase" data-automation-id="relational-classification">Relational: {{ getClassification(score.relational) }}</h3>
      <app-fire-content-block id="wfRelationalDesc"></app-fire-content-block>
      <app-fire-content-block id="{{getContentBlock('Relational', score.relational)}}"></app-fire-content-block>

      <h3 class="font-family-condensed-extra font-size-h4 text-uppercase" data-automation-id="physical-classification">Physical: {{ getClassification(score.physical) }}</h3>
      <app-fire-content-block id="wfPhysicalDesc"></app-fire-content-block>
      <app-fire-content-block id="{{getContentBlock('Physical', score.physical)}}"></app-fire-content-block>

      <h3 class="font-family-condensed-extra font-size-h4 text-uppercase" data-automation-id="intellectual-classification">Biblical Truth: {{ getClassification(score.intellectual) }}</h3>
      <app-fire-content-block id="wfIntellectualDesc"></app-fire-content-block>
      <app-fire-content-block id="{{getContentBlock('Intellectual', score.intellectual)}}"></app-fire-content-block>

      <h3 class="font-family-condensed-extra font-size-h4 text-uppercase" data-automation-id="financial-classification">Financial: {{ getClassification(score.financial) }}</h3>
      <app-fire-content-block id="wfFinancialDesc"></app-fire-content-block>
      <app-fire-content-block id="{{getContentBlock('Financial', score.financial)}}"></app-fire-content-block>

      <h2 class="section-header push-half-bottom" data-automation-id="classification">You're {{ getClassification(getAverage(score)) }}</h2>
      <app-fire-content-block id="{{getContentBlock('Final', getAverage(score))}}"></app-fire-content-block>
      <p class="push-top">
        <a (click)="navigateToOverview()" *ngIf="false" class="btn btn-blue">See more</a>
      </p>
    </div>
  </div>
</div>