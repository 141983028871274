<div class="container animatable">
  <div class="row padding">&nbsp;</div>
  <div class="row">
    <div class="encouragement-text tagline">
      <p>Time for a break!</p>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <div data-automation-id="wf-breath-content" class="question">
        <div class="soft-quarter-top tagline" *ngIf="breath == 1">
          <app-fire-content-block id="wfBreath40"></app-fire-content-block>
        </div>

        <div class="soft-quarter-top tagline" *ngIf="breath == 2">
          <app-fire-content-block id="wfBreath80"></app-fire-content-block>
        </div>
      </div>
      <button type="button" data-automation-id="wf-breath-continue-btn" class="btn btn-blue btn-block btn-lg" (click)="moreQuestions()">Keep Going</button>
    </div>
  </div>
</div>
