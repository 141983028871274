import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-results-overview-detail",
  templateUrl: "./results-overview-detail.component.html"
})
export class ResultsOverviewDetailComponent implements OnInit {
  public contentBlock = "";
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.contentBlock = `${this.route.snapshot.url.join("")}Overview`;
  }
}
