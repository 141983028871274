import { makeVar, ReactiveVar } from "@apollo/client/core";
import { isString } from "lodash";

type ExpiringPersistedVar<T> = {
  exp: number;
  var: {
    [K in keyof T]: T[K];
  };
};
const getCleanValueForStorage = (value: unknown): string => {
  return isString(value) ? <string>value : JSON.stringify(value);
};

const makeVarPersisted = <T>(
  initialValue: T,
  storageName: string,
  expirationInSeconds: number
): ReactiveVar<T> => {
  let value: ExpiringPersistedVar<T> = {
    var: initialValue,
    exp: Date.now() + expirationInSeconds * 1000
  };

  // Try to fetch the value from local storage
  const previousValue = localStorage.getItem(storageName);
  if (previousValue !== null) {
    try {
      const parsed: ExpiringPersistedVar<T> = JSON.parse(previousValue);
      if (parsed.exp > Date.now()) {
        value = parsed;
      } else localStorage.removeItem(storageName);
    } catch {
      // It wasn't JSON, assume a valid value
      value = (previousValue as unknown) as ExpiringPersistedVar<T>;
    }
  }

  // Create a reactive var with stored/initial value
  const rv = makeVar<T>(value.var);

  const onNextChange = (newValue: T | undefined) => {
    try {
      // Try to add the value to local storage
      if (newValue === undefined) {
        localStorage.removeItem(storageName);
      } else {
        let value: ExpiringPersistedVar<T> = {
          var: newValue,
          exp: Date.now() + expirationInSeconds * 1000
        };
        localStorage.setItem(storageName, getCleanValueForStorage(value));
      }
    } catch {
      // ignore
    }

    // Re-register for the next change
    rv.onNextChange(onNextChange);
  };

  // Register for the first change
  rv.onNextChange(onNextChange);

  return rv;
};

export default makeVarPersisted;
