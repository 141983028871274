import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ContentFragment } from "../../../generated/graphql";
import { ContentService } from "../../services/content.service";
import { percentageToUpdateProgressBar } from "../../shared/constants";

@Component({
  selector: "app-question-progress-bar",
  templateUrl: "./question-progress-bar.component.html",
  styleUrls: ["./question-progress-bar.component.scss"]
})
export class QuestionProgressBarComponent implements OnInit, OnChanges {
  @Input() max: number;
  @Input() answered: number;
  public encouragement: ContentFragment;
  public ready = false;
  private shownAnswered: number = null;
  private encouragements: Observable<ContentFragment[]>;
  private modulus: number;
  constructor(public contentService: ContentService) {}

  ngOnInit() {
    this.modulus = this.max * percentageToUpdateProgressBar;
    this.encouragements = this.contentService.getEncouragements();
    this.updateNumberOfQuestionsAnswered();
    this.ready = true;
  }

  ngOnChanges() {
    // Happens before NgOnInit
    if (this.ready) {
      this.updateNumberOfQuestionsAnswered();
    }
  }

  public GetPercentage() {
    if (this.shownAnswered === 0) {
      return 0 + "%";
    } else if (this.shownAnswered === this.max) {
      return 99 + "%";
    } else {
      return (this.shownAnswered / this.max) * 100 + "%";
    }
  }

  private updateNumberOfQuestionsAnswered() {
    this.encouragements
      .pipe(
        map(resEncouragments => {
          // if we havent set questions answered or if we've answered the correct number of questions
          // update totalQuestionsAnswered and update the progress bar.
          if (
            this.shownAnswered == null ||
            this.answered % this.modulus === 0
          ) {
            this.shownAnswered = Math.floor(this.answered / 10) * 10; // round down to nearest 10
            const index =
              this.shownAnswered / 10 < 0 ? 0 : this.shownAnswered / 10;
            if (index < resEncouragments.length - 1) {
              return resEncouragments[index];
            } else {
              return resEncouragments.slice(-1)[0];
            }
          }
        })
      )
      .subscribe(resEncouragments => {
        this.encouragement = resEncouragments || this.encouragement;
      });
  }
}
