import { AbstractControl } from "@angular/forms";

export const emailMatcher = (control: AbstractControl): void => {
  const email = control.get("email");
  const emailConf = control.get("emailConf");

  if (!email || !emailConf) return null;
  return email.value === emailConf.value
    ? emailConf.setErrors(null)
    : emailConf.setErrors({ nomatch: true });
};
