import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html"
})
export class FooterComponent {
  public hasMargin = true;

  constructor(private router: Router) {
    this.evalPath();
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.evalPath();
      }
    });
  }
  evalPath() {
    // don't show the top margin on the footer when on these paths
    let url = this.router.url.toString();
    this.hasMargin = url !== "/" && !url.startsWith("/faq");
  }
}
