<div class="container push-bottom">
  <div class="row" *ngIf="ready">
    <div class="col-xs-12 col-md-8 col-md-offset-2" data-automation-id="wf-results-resume">
      <app-fire-content-block id="resumeWayfinder" [data-automation-id]="resumeWayfinderContentBlock"></app-fire-content-block>
      <div class="btn-block-mobile full-width push-top text-center">
        <button class="btn btn-blue push-quarter mobile-flush" (click)="resume()">Resume</button>
        <button class="btn btn-blue btn-outline" (click)="clearOldAssessment()">Restart</button>
      </div>
    </div>
  </div>
</div>