import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-instructions",
  templateUrl: "./instructions.component.html"
})
export class InstructionsComponent implements OnInit {
  private pages = {
    "/setup/1": {
      content: "wfSetup1",
      nextURL: "/setup/2"
    },
    "/setup/2": {
      content: "wfSetup2",
      nextURL: "/setup/3"
    },
    "/setup/3": {
      content: "wfSetup3",
      nextURL: "/setup/4"
    },
    "/setup/4": {
      content: "wfSetup4",
      nextURL: "/setup/5"
    },
    "/setup/5": {
      content: "wfSetup5",
      nextURL: "/setup/6"
    },
    "/setup/6": {
      content: "wfSetup6",
      nextURL: "/info"
    }
  };
  public currentPage;

  constructor(private router: Router) {}

  ngOnInit() {
    this.currentPage = this.pages[this.router.url];
  }

  doClick() {
    this.router.navigateByUrl(this.currentPage.nextURL);
  }

  getContentId(): string {
    return this.currentPage.content;
  }
}
