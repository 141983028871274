import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { GetPreloaderGQL } from "../../../generated/graphql";
import { preloader } from "../../shared/state/state.resolver";

@Injectable()
export class PreLoaderService {
  constructor(private getPreloader: GetPreloaderGQL) {}

  showLoader() {
    return this.getPreloader.watch().valueChanges.pipe(
      map(result => {
        return result.data.preloader;
      })
    );
  }
}
