import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Angulartics2GoogleTagManager } from "angulartics2/gtm";
import { Angulartics2Segment } from "angulartics2/segment";
import { AuthService } from "./services/auth.service";
import { ContentService } from "./services/content.service";
import { PreLoaderService } from "./components/preloader/preloader.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  showHeader = false;
  ready = false;

  constructor(private router: Router, private preloader: PreLoaderService) {}

  ngOnInit(): void {
    this.showHeader = window.location.pathname.toUpperCase().includes("FAQ");

    this.scrollToTop();
    this.preloader.showLoader().subscribe(loading => {
      if (!loading) {
        this.ready = true;
      }
    });
  }

  private scrollToTop() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeader = true;
        window.scrollTo(0, 0);
        if (this.router.url === "/") {
          this.showHeader = false;
        }
      }
    });
  }
}
