<div class="container">
  <div class="row">
    <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 soft-ends mobile-flush-top push-half-top">
      <div class="progress" *ngIf="ready">
        <div class="progress-bar progress-bar-teal" [ngStyle]="{'width': GetPercentage() }"></div>
      </div>
      <div class="soft-quarter-top tagline">
        <span [innerHtml]="encouragement.content | safeHtml" class="encouragement-text" attr.data-automation-id="progress-text"></span>
      </div>
    </div>
  </div>
</div>