import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AssessmentService } from "../../services/assessment.service";
import { Router } from "@angular/router";
import { AnalyticsService } from "../../services/analytics.service";
import { StateService } from "../../services/state.service";
import { HttpClient } from "@angular/common/http";
import { cloneDeep } from "@apollo/client/utilities";

@Component({
  selector: "app-finalquestion-page",
  templateUrl: "./finalquestion-page.component.html",
  styleUrls: ["../question-progress-bar/question-progress-bar.component.scss"]
})
export class FinalquestionPageComponent implements OnInit {
  public learnedForm: FormGroup;
  public isSubmitted = false;
  private sendReflectionUrl: string;

  constructor(
    private assessmentService: AssessmentService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private stateService: StateService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.sendReflectionUrl = `https://us-central1-.cloudfunctions.net/sendReflectionEmailAttachment`;
    this.learnedForm = new FormGroup({
      learned: new FormControl("", Validators.required)
    });
  }

  public submitForm(theForm: FormGroup) {
    // save the response in our local service
    this.assessmentService.GetAssessment().subscribe(assessment => {
      this.assessmentService
        .UpdateAssessmentProperty("completedDate", `${new Date()}`)
        .subscribe(_ => {
          this.sendReflectionScheduleResults(assessment._id).subscribe();

          this.analyticsService.trackWayfinderAssessmentCompleted(
            assessment._id
          );
          // navigate to the thank you page
          this.router.navigate([this.stateService.GetFinalPage()]);
        });
    });
  }

  private sendReflectionScheduleResults(assessmentId: string) {
    return this.assessmentService.SendAssessmentEmail(assessmentId, false);
  }
}
