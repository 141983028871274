import { Component, OnInit } from "@angular/core";
import { AssessmentService } from "../../services/assessment.service";
import { Score } from "../../models/score";
import { categoryBoundries } from "../../shared/constants";
import { Router, ActivatedRoute } from "@angular/router";
import { StateService } from "../../services/state.service";
import { AssessmentFragment } from "../../../generated/graphql";

@Component({
  templateUrl: "./results.component.html"
})
export class ResultsComponent implements OnInit {
  public score: Score;
  public assessment: AssessmentFragment;

  constructor(
    private assessmentService: AssessmentService,
    private router: Router,
    private route: ActivatedRoute,
    private state: StateService
  ) {}

  ngOnInit() {
    this.assessmentService.GetScore().subscribe(score => {
      this.score = score;
    });

    this.assessmentService.GetAssessment().subscribe(assessment => {
      this.assessment = assessment;

      if (this.assessment.resultsEmailSent === false) {
        console.log("WAYFINDER: we used to upload a picture here");
      } else {
        console.log("WAYFINDER: Results Email not sent again");
      }
    });

    if (this.state.GetDebugMode()) {
      this.logScores();
    }
  }

  public getClassification(score: number): string {
    const roundedScore = Math.ceil(score);
    if (roundedScore <= categoryBoundries.SEEKING_HIGH) {
      return "Seeking";
    }
    if (
      roundedScore >= categoryBoundries.RECEIVING_LOW &&
      roundedScore <= categoryBoundries.RECEIVING_HIGH
    ) {
      return "Receiving";
    }
    if (
      roundedScore >= categoryBoundries.FOLLOWING_LOW &&
      roundedScore <= categoryBoundries.FOLLOWING_HIGH
    ) {
      return "Following";
    } else {
      return "Producing";
    }
  }

  public navigateToOverview(): void {
    const classification = this.getClassification(
      this.score.spiritual
    ).toLocaleLowerCase();
    this.router.navigate(["./overview-of-results/" + classification], {
      relativeTo: this.route
    });
  }

  public getContentBlock(category: string, score: number): string {
    return `wf${category}${this.getClassification(score)}`;
  }

  public getAverage(score: Score) {
    return (
      (score.financial +
        score.intellectual +
        score.physical +
        score.relational +
        score.spiritual) /
      5
    );
  }

  private logScores(): void {
    const keys = Object.keys(this.score);
    keys.forEach(element => {
      console.log(`${element}: ${this.score[element]}`);
    });

    console.log(`Overall: ${this.getAverage(this.score)}`);
  }
}
