import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { AnalyticsService } from "../../services/analytics.service";
import { AssessmentService } from "../../services/assessment.service";
import { Howl } from "howler";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-audio-player",
  templateUrl: "./audio-player.component.html",
  styleUrls: ["./audio-player.component.scss"]
})
export class AudioPlayerComponent implements OnInit {
  private audio: Howl;
  private fadeTime = 300;
  private volumeLow = 0;
  private volumeHigh = 1;
  public audioLoaded = false;
  public audioPlaying = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private assessmentService: AssessmentService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.audio = new Howl({
      src: [
        "https://s3.amazonaws.com/crds-cms-uploads/media/music/Ash-Wednesday-Room-3-01-02.mp3"
      ],
      preload: true,
      loop: true,
      volume: this.volumeLow,
      html5: true,
      onfade: () => {
        if (this.audio.volume() == this.volumeLow) {
          this.audio.pause();

          this.getAssessmentId().subscribe(id => {
            this.analyticsService.trackWayfinderBGMusicPaused(id);
          });
        }
        if (this.audio.volume() == this.volumeHigh) {
          this.audio.play();

          this.getAssessmentId().subscribe(id => {
            this.analyticsService.trackWayfinderBGMusicPaused(id);
          });
        }
      },
      onload: () => {
        this.audioLoaded = true;
        this.changeDetector.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.audio.unload();
  }

  public changeAudioState() {
    if (!this.audioPlaying) {
      this.audio.fade(this.volumeLow, this.volumeHigh, this.fadeTime);
      this.audioPlaying = true;
    } else {
      this.audio.fade(this.volumeHigh, this.volumeLow, this.fadeTime);
      this.audioPlaying = false;
    }
  }

  private getAssessmentId(): Observable<string> {
    return this.assessmentService.GetAssessmentId().pipe(
      tap(id => {
        console.log("ass id : " + this.assessmentService.GetAssessmentId());
      })
    );
  }
}
