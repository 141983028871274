import { InMemoryCache, makeVar } from "@apollo/client/core";
import {
  AnswerFragment,
  AssessmentFragment,
  ConfigFragment,
  ContentFragment,
  QuestionFragment,
  UserFragment
} from "../../../generated/graphql";
import makeVarPersisted from "./makeVarPersisted";

const timeToAllowResumeInSeconds = 7200; //2 hours

export const answers = makeVar(<AnswerFragment[]>[]);
export const assessment = makeVarPersisted(
  <AssessmentFragment>{},
  "wayfinderAssessment",
  timeToAllowResumeInSeconds
);
export const config = makeVar(<ConfigFragment>{});
export const content = makeVar(<ContentFragment[]>[]);
export const currentUser = makeVarPersisted(
  <UserFragment>{},
  "wayfinderUser",
  timeToAllowResumeInSeconds
);
export const questions = makeVar(<QuestionFragment[]>[]);
export const preloader = makeVar(true);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        answers: {
          read() {
            return answers();
          }
        },
        assessment: {
          read() {
            return assessment();
          }
        },
        config: {
          read() {
            return config();
          }
        },
        content: {
          read() {
            return content();
          }
        },
        currentUser: {
          read() {
            return currentUser();
          }
        },
        questions: {
          read() {
            return questions();
          }
        },
        preloader: {
          read() {
            return preloader();
          }
        }
      }
    }
  }
});
