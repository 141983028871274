<div class="container">
  <div class="row">
    <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <form [formGroup]="questionForm" [@questionState]="state" (@questionState.done)="afterAnimation($event)">
        <div class="form-group">
          <p data-automation-id="wf-quiz-questionText" class="question">
            <app-question-markup-composer [questionText]="response.question?.questiontext" [questionId]="response.question?.questionid"></app-question-markup-composer>
          </p>
          <div class="btn-group btn-group-block" role="group">
            <button #options formControlName="question" *ngFor="let answer of answers" type="button" class="btn btn-default" [(ngModel)]="selectedAnswerId"
              appDebounceClick (debounceClick)="answerSelected()" [debounceTime]="250" attr.data-automation-id="wf-quiz-answer{{answer.sequence}}"
              [btnRadio]="answer._id">{{answer.answertext}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>