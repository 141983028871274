
import {of as observableOf,  Observable } from 'rxjs';
import { CRDSSite } from "../models/crds.site";
import { Injectable } from "@angular/core";

@Injectable()
export class CRDSSiteService {
  public getSites(): Observable<CRDSSite[]> {
    return observableOf([
      new CRDSSite("Anywhere", 15),
      new CRDSSite("Columbus", 23),
      new CRDSSite("Dayton", 22),
      new CRDSSite("Downtown Lexington", 24),
      new CRDSSite("East Side", 17),
      new CRDSSite("Florence", 7),
      new CRDSSite("Georgetown", 18),
      new CRDSSite("Lexington", 21),
      new CRDSSite("Mason", 6),
      new CRDSSite("Oakley", 1),
      new CRDSSite("Oxford", 16),
      new CRDSSite("Richmond", 19),
      new CRDSSite("Uptown", 11),
      new CRDSSite("West Side", 8)
    ]);
  }
}
