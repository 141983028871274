<div class="wrapper">
  <div class="container ">
    <h1>FAQs</h1>
    <div *ngIf="route.queryParamMap | async as qp">
      <div>
        <app-fire-content-block id="wfFAQ"></app-fire-content-block>
      </div>
      <div class="text-center ">
        <button *ngIf="!(qp.params.new && qp.params.new === 'true')" class="btn btn-blue btn-lg" role="button" (click)="goHome()">Home</button>
      </div>
    </div>
  </div>
</div>
