import { Injectable } from "@angular/core";
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { StopAndBreatheComponent } from "../components/stop-and-breathe/stop-and-breathe.component";
import { Observable } from "rxjs";
import { PlatformLocation } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { ContentService } from "../services/content.service";

@Injectable()
export class StopAndBreatheGuard
  implements CanDeactivate<StopAndBreatheComponent> {
  constructor(
    private toast: ToastrService,
    private location: PlatformLocation,
    private content: ContentService
  ) {}

  canDeactivate(
    _component: StopAndBreatheComponent,
    _currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (nextState.url === "/questions" &&
        currentState.url === "/stopandbreathe?breathe=inhale") ||
      (nextState.url === "/questions?forward=1" &&
        currentState.url === "/stopandbreathe?breathe=exhale")
    ) {
      this.showToastError();
      this.location.pushState(null, null, currentState.url);
      return false;
    }
    return true;
  }

  private showToastError() {
    this.content.getContent("wfBackError").subscribe(block => {
      const text = block
        ? block.content
        : "Sorry, you may not go back once you've started.";
      this.toast.error(text, "", {
        timeOut: 3000
      });
    });
  }
}
